import React, { useEffect, useState } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import AccessDenide from "../AccessDenide/AccessDenide";
import {
  formatAmountInr,
  authTokenHeader,
  ServiceURL,
  getFlooredFixed,
  formatNumberNoDacimal,
  SessionOut,
} from "../Utils/utils";
import Footer from "../Footer";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const InvoiceOrderDetails = () => {
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);
  let params = useParams();
  const orderId = params.id;
  const [updateOrderDetails, setUpdateOrderDetails] = useState(true);
  const [customerMobile, setCustomerMobile] = React.useState("");
  const [customerName, setCustomerName] = React.useState("");
  const [customerEmail, setCustomerEmail] = React.useState("");
  const [customerAddress, setCustomerAddress] = React.useState("");
  const [customerId, setCustomerID] = useState("");

  const [productInfoExtra, setProductInfoExtra] = React.useState("");
  const [inrAnyExtra, setInrAnyExtra] = React.useState(0);

  const [taxRateIs, setTaxRateIs] = React.useState(0);
  const [taxAmount, setTaxAmount] = React.useState(0);
  const [amountTotal, setAmountTotal] = React.useState(0);

  const [taxName, setTaxName] = React.useState("");

  const [finalInvoicePrice, setFinalInvoicePrice] = React.useState(0);

  const [calculateButtonText, setCalculateButtonText] = React.useState(
    "Calculate Total & Final Price"
  );

  const [exchangeProductName, setExchangeProductName] = React.useState("");
  const [exchangeGramsWt, setExchangeGramsWt] = React.useState(0);
  const [exchangePurity, setExchangePurity] = React.useState(0);
  const [exchangeNetWt, setExchangeNetWt] = React.useState(0);
  const [exchangePriceRateOnGram, setExchangePriceRateOnGram] =
    React.useState(0);
  const [exchangeAmount, setExchangeAmount] = React.useState(0);

  const [grandTotalPrice, setGrandTotalPrice] = React.useState(0);

  const [proceedOfButton, setProceedOfButton] = React.useState(true);

  const [allProducts, setAllProducts] = React.useState([]);

  const [allStocks, setAllStocks] = React.useState([]);

  const [exchangeMelting, setExchangeMelting] = React.useState(0);

  const [inputHandleDisable, setInputHandleDisable] = React.useState(false);

  const [exchangeDivShow, setExchangeDivShow] = React.useState(false);

  const [totalAfterTax, setTotalAfterTax] = React.useState(0);

  const [removeTaxAmount, setRemoveTaxAmount] = React.useState(true);

  const [finalDiscountAmount, setFinalDiscountAmount] = React.useState(0);

  const [taxableAmountIs, setTaxableAmountIs] = React.useState(0);

  const token = authTokenHeader();
  const serviceURl = ServiceURL();
  const navigator = useNavigate();

  const [stockAvlblGrams, setStockAvlbl] = React.useState("");

  const getStockInfoById = (stockIdIs) => {
    const getStockDetails = async () => {
      const endpoint = serviceURl + `stock/${stockIdIs}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          user_header_key: token,
        },
      };
      await fetch(endpoint, options)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setStockAvlbl(data.results[0].stock_available);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              alert(data.message);
              navigator("/dashboard");
            }
          }
        });
    };
    getStockDetails();
  };

  useEffect(() => {
    const fetchngTaxDetails = async () => {
      const taxURl = serviceURl + `tax/details/${16073045}`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json", user_header_key: token },
      };
      await fetch(taxURl, options)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setTaxName(data.results[0].tax_name);
            setTaxRateIs(data.results[0].tax_percentage);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              alert(data.message);
              alert("Contact Developer @ 8074725033");
              navigator("/dashboard");
            }
          }
        });
    };

    const fetchngOrderDetails = async () => {
      const taxURl = serviceURl + `invoice/by/${orderId}`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json", user_header_key: token },
      };
      await fetch(taxURl, options)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setCustomerID(data.results[0].customerDetails[0].user_id);
            setCustomerMobile(data.results[0].customerDetails[0].user_mobile);
            setCustomerName(data.results[0].customerDetails[0].user_name);
            setCustomerEmail(data.results[0].customerDetails[0].user_email);
            setCustomerAddress(data.results[0].customerDetails[0].user_address);

            setAllProducts(
              data.results.map((item) => {
                return {
                  id: item.id,
                  stock_id: item.stock_id,
                  product_name: item.product_name,
                  one_gram_price: item.one_gram_price,
                  gross_weight: item.grams_weight,
                  net_weight: item.net_weight,
                  amount: item.amount,
                  stones_amount: item.stone_amount,
                  value_added: item.value_added,
                  value_added_amount: item.value_added_amount,
                  item_status: item.item_status === "" ? 0 : item.item_status,
                  sub_total_amount: item.sub_total,
                };
              })
            );

            setProductInfoExtra(data.results[0].extra_description);
            setInrAnyExtra(data.results[0].extra_amount);

            setRemoveTaxAmount(data.results[0].tax_amount > 0 ? false : true);

            setFinalDiscountAmount(data.results[0].discount);

            setInputHandleDisable(false);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              alert(data.message);
              alert("Contact Developer @ 8074725033");
              navigator("/dashboard");
            }
          }
        });
    };

    const GetListOfStocks = async () => {
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json", user_header_key: token },
      };
      const endpoint = serviceURl + "stock/list";
      await fetch(endpoint, options)
        .then((res) => res.json())
        .then((data) => {
          if (data.success === false) {
            if (data.message === "invalidToken") {
              alert("Lost Session!, Login Again!");
              window.location.href = "/login";
            } else if (data.message === "internalServerError") {
              alert("Internal Server Error!, Login Again!");
              window.location.href = "/login";
            } else {
              alert(data.message);
            }
          } else {
            setAllStocks(data.results);
          }
        });
    };

    if (updateOrderDetails) {
      GetListOfStocks();
      fetchngTaxDetails();
      fetchngOrderDetails();
    }
  }, []);

  const handelSubmit = async (e) => {
    e.preventDefault();
    const updateOrder = {
      extra_description: productInfoExtra,
      extra_amount: inrAnyExtra,

      invoice_products: allProducts,

      discount: finalDiscountAmount,
      tax_persantage: taxRateIs,
      tax_amount: taxAmount,
      total: grandTotalPrice,
    };

    const options = {
      method: "PUT",
      headers: { "Content-Type": "application/json", user_header_key: token },
      body: JSON.stringify(updateOrder),
    };
    const endpoint = serviceURl + `invoice/update/${orderId}`;
    await fetch(endpoint, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert(data.message);
          navigator(`/invoice-order-view/${data.sale_id}`);
        } else {
          if (data.message === "invalidToken") {
            SessionOut("invalidToken");
          } else if (data.message === "internalServerError") {
            SessionOut("internalServerError");
          } else {
            alert(data.message);
          }
        }
      });
  };

  const calculateFinalPrice = () => {
    const totalAmountProducts = allProducts.reduce((accumulator, object) => {
      return accumulator + object.sub_total_amount;
    }, 0);
    let totalAmountIs = 0;
    totalAmountIs = getFlooredFixed(
      Number(totalAmountProducts) + Number(inrAnyExtra),
      3
    );
    let discountAmount = finalDiscountAmount;
    totalAmountIs = getFlooredFixed(
      Number(totalAmountIs) - Number(discountAmount),
      3
    );
    setTaxableAmountIs(parseInt(totalAmountIs));
    const totalAmountProductsNow = getFlooredFixed(totalAmountProducts, 3);
    setAmountTotal(totalAmountProductsNow);
    let taxAmountIs = 0;
    if (!removeTaxAmount) {
      taxAmountIs = (totalAmountIs * taxRateIs) / 100;
    }
    setTaxAmount(getFlooredFixed(taxAmountIs, 3));
    const finalInvoicePriceIs = Number(totalAmountIs) + Number(taxAmountIs);
    setFinalInvoicePrice(getFlooredFixed(finalInvoicePriceIs, 3));
    setTotalAfterTax(getFlooredFixed(finalInvoicePriceIs, 3));
    const grandTotal = Number(finalInvoicePriceIs) - Number(exchangeAmount);
    setGrandTotalPrice(Math.round(grandTotal));
    setCalculateButtonText("Calculate Total & Final Price");
    alert("Calculated Successfully");
    setProceedOfButton(false);
    if (grandTotal > 0) {
      setProceedOfButton(false);
    }
  };

  const handleInputChange = (e, i) => {
    const name = e.target.name;
    const value =
      e.target.type === "number" ? parseFloat(e.target.value) : e.target.value;
    const onChangeValue = [...allProducts];

    onChangeValue[i][name] = value;

    if (name === "stock_id") {
      onChangeValue[i]["product_name"] = allStocks.filter(
        (x) => x.stock_id === e.target.value
      )[0]?.stock_name;
    }
    const oneGramPrice = onChangeValue[i]["one_gram_price"];
    const netWt = onChangeValue[i]["net_weight"];
    const stonesAmount = onChangeValue[i]["stones_amount"];
    const valueAddedPer = onChangeValue[i]["value_added"];

    const calAmount = netWt * oneGramPrice;
    onChangeValue[i]["amount"] = calAmount;

    const calVa = (calAmount * valueAddedPer) / 100;
    onChangeValue[i]["value_added_amount"] = calVa;

    onChangeValue[i]["sub_total_amount"] = calAmount + calVa + stonesAmount;

    setAllProducts(onChangeValue);
    setCalculateButtonText("Re-Calculate Now");
    setProceedOfButton(true);
  };

  const clearTotalAmount = () => {
    setUpdateOrderDetails(false);
    setAmountTotal(0);
    setTaxAmount(0);
    setFinalInvoicePrice(0);
    setGrandTotalPrice(0);
    setCalculateButtonText("Re-Calculate Now");
    setProceedOfButton(true);
  };

  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"updateOrder"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Update Sale</h1>
            <hr />
            {accessOnComponent ? (
              <form onSubmit={handelSubmit}>
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        id="mobileNumber"
                        placeholder="Enter Customer Mobile Number"
                        value={customerMobile}
                        readOnly
                        onChange={(e) => {
                          setCustomerMobile(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="card mt-3">
                  <div className="card-body mb-2">
                    <h5 className="card-title">Customer Details : </h5>
                    <div className="row">
                      <div className="form-group col-md-6 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="fullName"
                          placeholder="Customer Name"
                          autoComplete="off"
                          value={customerName}
                          required
                          readOnly={true}
                          onChange={(e) => {
                            setCustomerName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group col-md-6 mb-3">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Customer Email (Optional)"
                          autoComplete="off"
                          value={customerEmail}
                          readOnly={true}
                          onChange={(e) => {
                            setCustomerEmail(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control mb-1"
                        id="inputAddress"
                        placeholder="Customer Address : 1234 Main St"
                        autoComplete="off"
                        required
                        readOnly={true}
                        value={customerAddress}
                        onChange={(e) => {
                          setCustomerAddress(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Product Details : </h5>
                    {allProducts.map((value, i) => {
                      return (
                        <div
                          className="border border-primary rounded p-3 mb-3 shadow"
                          id="orderDivIs"
                          key={i}
                        >
                          <div className="row">
                            <div className="form-group col-md-4 mb-3">
                              <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                  <label
                                    class="input-group-text"
                                    for="inputGroupSelect01"
                                  >
                                    Select Stock :
                                  </label>
                                </div>
                                <select
                                  class="custom-select"
                                  name="stock_id"
                                  id="stock_id"
                                  value={value.stock_id}
                                  onChange={(e) => {
                                    handleInputChange(e, i);
                                    getStockInfoById(e.target.value);
                                  }}
                                  required
                                  disabled={true}
                                >
                                  <option value="">Choose...</option>
                                  {allStocks.map((value, i) => {
                                    return (
                                      <option key={i} value={value.stock_id}>
                                        {value.stock_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="form-group col-md-4 input-group-sm mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="product_name"
                                name="product_name"
                                placeholder="Product Name"
                                autoComplete="off"
                                value={value.product_name}
                                required
                                readOnly={true}
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="basic-grams"
                                  >
                                    1 Gram Price (INR):
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="one_gram_price"
                                  name="one_gram_price"
                                  placeholder="(eg. 5650.75)"
                                  autoComplete="off"
                                  min="1"
                                  step="any"
                                  value={value.one_gram_price}
                                  required
                                  readOnly={true}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-md-4">
                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="basic-grams"
                                  >
                                    Gross Weight:
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="gross_weight"
                                  name="gross_weight"
                                  placeholder="(eg. 12.75)"
                                  autoComplete="off"
                                  step="any"
                                  value={
                                    value.gross_weight === 0
                                      ? ""
                                      : value.gross_weight
                                  }
                                  required
                                  readOnly={inputHandleDisable ? true : false}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </div>
                            </div>
                            <div className="form-group col-md-4">
                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="basic-symbol"
                                  >
                                    Net Weight:
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="net_weight"
                                  name="net_weight"
                                  placeholder="(eg. 12.75)"
                                  value={
                                    value.net_weight === 0
                                      ? ""
                                      : value.net_weight
                                  }
                                  autoComplete="off"
                                  step="any"
                                  required
                                  readOnly={inputHandleDisable ? true : false}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </div>
                            </div>
                            <div className="form-group col-md-4">
                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="basic-grams"
                                  >
                                    Stones (INR):
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="stones_amount"
                                  name="stones_amount"
                                  placeholder="Amount (eg. 1200)"
                                  autoComplete="off"
                                  min="0"
                                  step="any"
                                  value={
                                    value.stones_amount === 0
                                      ? ""
                                      : value.stones_amount
                                  }
                                  onChange={(e) => handleInputChange(e, i)}
                                  readOnly={inputHandleDisable ? true : false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-md-4">
                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="basic-symbol"
                                  >
                                    VA @ % :
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="value_added"
                                  name="value_added"
                                  placeholder="Value Added (eg. 95)"
                                  step="any"
                                  value={
                                    value.value_added === 0
                                      ? ""
                                      : value.value_added
                                  }
                                  onChange={(e) => handleInputChange(e, i)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-group col-md-4">
                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="basic-grams"
                                  >
                                    VA Amount (INR):
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="value_added_amount"
                                  name="value_added_amount"
                                  placeholder="0.00"
                                  autoComplete="off"
                                  min="0"
                                  step="any"
                                  value={
                                    value.value_added_amount === 0
                                      ? ""
                                      : value.value_added_amount
                                  }
                                  onChange={(e) => handleInputChange(e, i)}
                                  readOnly={true}
                                />
                              </div>
                            </div>
                            <div className="form-group col-md-4">
                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="basic-symbol"
                                  >
                                    Item Status:
                                  </span>
                                </div>
                                <select
                                  class="form-control"
                                  id="item_status"
                                  name="item_status"
                                  required
                                  defaultValue={value.item_status}
                                  onChange={(e) => handleInputChange(e, i)}
                                  disabled={inputHandleDisable ? true : false}
                                >
                                  <option value="0">Item Paid ( Given )</option>
                                  <option value="1">
                                    Item Due ( Pending )
                                  </option>
                                </select>
                                {/* <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Amount (eg. 1600)"
                                  id="stones_amount"
                                  name="stones_amount"
                                  min="0"
                                  step="any"
                                  value={value.stones_amount}
                                  onChange={(e) => handleInputChange(e, i)}
                                  readOnly={inputHandleDisable ? true : false}
                                /> */}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="basic-symbol"
                                  >
                                    Total (INR):
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="0.00"
                                  id="sub_total_amount"
                                  name="sub_total_amount"
                                  min="0"
                                  value={
                                    value.sub_total_amount === 0
                                      ? ""
                                      : value.sub_total_amount
                                  }
                                  onChange={(e) => handleInputChange(e, i)}
                                  required
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="col-md-6 text-center my-auto">
                              <b>
                                Product : <g className="text-info">{i + 1}</g>
                              </b>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <hr></hr>
                    {exchangeDivShow ? (
                      <>
                        <div
                          className="accordion shadow border border-info"
                          id="accordionExchange"
                        >
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                <b className="mr-1">+</b> Add -{" "}
                                <b className="ml-1 mr-1">Old / Exchange </b> -
                                If Any
                              </button>
                            </h2>
                            <div
                              id="collapseOne"
                              className={`accordion-collapse collapse ${
                                exchangeDivShow ? "show" : ""
                              }`}
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExchange"
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-md-6 mb-3">
                                    <div className="input-group input-group-sm">
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text"
                                          id="basic-symbol"
                                        >
                                          Ex. Product Name:
                                        </span>
                                      </div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Exchange Product Name"
                                        aria-label="Exchnage"
                                        aria-describedby="basic-symbol"
                                        value={exchangeProductName}
                                        onChange={(e) => {
                                          setExchangeProductName(
                                            e.target.value
                                          );
                                        }}
                                        readOnly={
                                          inputHandleDisable ? true : false
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 mb-2">
                                    <div className="input-group input-group-sm">
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text"
                                          id="basic-symbol"
                                        >
                                          Exchange Rate On Gram (INR):
                                        </span>
                                      </div>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Exchange Rate On Gram (eg. 4500)"
                                        aria-label="Exchnage"
                                        aria-describedby="basic-symbol"
                                        step="any"
                                        value={exchangePriceRateOnGram}
                                        onChange={(e) => {
                                          setExchangePriceRateOnGram(
                                            e.target.value !== 0
                                              ? e.target.value
                                              : 0
                                          );
                                          clearTotalAmount();
                                        }}
                                        readOnly={
                                          inputHandleDisable ? true : false
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 mb-3">
                                    <div className="input-group input-group-sm">
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text"
                                          id="basic-symbol"
                                        >
                                          Grams. WT:
                                        </span>
                                      </div>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Exchange Grams. WT (eg. 12.00)"
                                        aria-label="Exchnage"
                                        aria-describedby="basic-symbol"
                                        step="any"
                                        value={exchangeGramsWt}
                                        onChange={(e) => {
                                          setExchangeGramsWt(
                                            e.target.value !== 0
                                              ? e.target.value
                                              : 0
                                          );
                                          setExchangeMelting("");
                                          setExchangePurity("");
                                        }}
                                        readOnly={
                                          inputHandleDisable ? true : false
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 mb-3">
                                    <div className="input-group input-group-sm">
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text"
                                          id="basic-symbol"
                                        >
                                          Melting @ :
                                        </span>
                                      </div>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Melting (eg. 75.00)"
                                        aria-label="Exchnage"
                                        aria-describedby="basic-symbol"
                                        step="any"
                                        value={exchangeMelting}
                                        onChange={(e) => {
                                          setExchangeMelting(
                                            e.target.value !== 0
                                              ? e.target.value
                                              : 0
                                          );
                                          const MakePurity =
                                            exchangeGramsWt *
                                            (exchangeMelting / 100);
                                          setExchangePurity(
                                            getFlooredFixed(MakePurity, 3)
                                          );
                                          const totalAMountExchange =
                                            getFlooredFixed(
                                              MakePurity *
                                                exchangePriceRateOnGram,
                                              3
                                            );
                                          setExchangeAmount(
                                            totalAMountExchange
                                          );
                                        }}
                                        readOnly={
                                          inputHandleDisable ? true : false
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 mb-3">
                                    <div className="input-group input-group-sm">
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text"
                                          id="basic-symbol"
                                        >
                                          Purity ( In % ):
                                        </span>
                                      </div>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Exchange Purity (eg. 75.00)"
                                        aria-label="Exchnage"
                                        aria-describedby="basic-symbol"
                                        step="any"
                                        value={exchangePurity}
                                        onChange={(e) => {
                                          setExchangePurity(
                                            e.target.value !== 0
                                              ? e.target.value
                                              : 0
                                          );
                                        }}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <div className="input-group input-group-sm">
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text"
                                          id="basic-symbol"
                                        >
                                          Net. WT:
                                        </span>
                                      </div>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Exchange Net Weight (eg. 7.50)"
                                        aria-label="Exchnage"
                                        aria-describedby="basic-symbol"
                                        step="any"
                                        value={exchangeNetWt}
                                        onChange={(e) => {
                                          setExchangeNetWt(
                                            e.target.value !== 0
                                              ? e.target.value
                                              : 0
                                          );
                                        }}
                                        readOnly={
                                          inputHandleDisable ? true : false
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 mb-2">
                                    <div className="input-group input-group-sm">
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text"
                                          id="basic-symbol"
                                        >
                                          Exchange Amount (INR):
                                        </span>
                                      </div>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Exchange Amount (eg. 12000)"
                                        aria-label="Exchnage"
                                        aria-describedby="basic-symbol"
                                        step="any"
                                        value={exchangeAmount}
                                        onChange={(e) => {
                                          setExchangeAmount(
                                            e.target.value !== 0
                                              ? e.target.value
                                              : 0
                                          );
                                          clearTotalAmount();
                                        }}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}

                    <hr></hr>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="2"
                            placeholder="Enter If Any Extra... (eg. somthing)"
                            value={productInfoExtra}
                            onChange={(e) => {
                              setProductInfoExtra(e.target.value);
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-6 my-auto">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-symbol"
                            >
                              Extra Amount (INR):
                            </span>
                          </div>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Amount (eg. 5500)"
                            aria-label="TotalAmount"
                            aria-describedby="basic-symbol"
                            min="0"
                            value={inrAnyExtra}
                            onChange={(e) => {
                              setInrAnyExtra(
                                parseInt(
                                  e.target.value !== 0 ? e.target.value : 0
                                )
                              );
                              clearTotalAmount();
                            }}
                            required
                            readOnly={inputHandleDisable ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-6 text-center my-auto">
                        <small className="text-center">
                          Discount can only view in amount (To edit type and
                          type based discount add external future)
                        </small>
                      </div>
                      <div className="col-md-6">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-symbol"
                            >
                              Discount Amount :
                            </span>
                          </div>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Discount Amount"
                            onChange={(e) => {
                              setFinalDiscountAmount(parseInt(e.target.value));
                              clearTotalAmount();
                            }}
                            min="0"
                            step={"any"}
                            value={finalDiscountAmount}
                            aria-label="TotalAmount"
                            aria-describedby="basic-symbol"
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row mt-3">
                      <div className="col-md-6 my-auto text-center">
                        <button
                          type="button"
                          onClick={calculateFinalPrice}
                          className="btn baseBackGroundColor btn-block"
                        >
                          {calculateButtonText}
                        </button>
                        <br />
                        <small>
                          <div class="form-check">
                            <label class="form-check-label">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                defaultChecked={removeTaxAmount}
                                onChange={(e) => {
                                  setRemoveTaxAmount(e.target.checked);
                                  clearTotalAmount();
                                }}
                              />
                              <b>Remove GST from this SALE!</b>
                            </label>
                          </div>
                        </small>
                        <hr></hr>
                        <small>
                          Note :{" "}
                          <b className="baseColor">
                            Recaculate is must on every change
                          </b>
                        </small>
                      </div>
                      <div className="col-md-6">
                        <table className="table table-hover table-sm text-center mt-3">
                          <tbody>
                            <tr>
                              <th scope="row">Total Amount : </th>
                              <td>
                                <b>{formatAmountInr(amountTotal)}</b>
                              </td>
                            </tr>
                            {inrAnyExtra > 0 && (
                              <tr>
                                <th scope="row">Extra (Other) :</th>
                                <td>{formatAmountInr(inrAnyExtra)}</td>
                              </tr>
                            )}
                            <tr>
                              <th scope="row">Discount :</th>
                              <td>- {formatAmountInr(finalDiscountAmount)}</td>
                            </tr>
                            <tr>
                              <th scope="row">Taxable Amount :</th>
                              <td>
                                <b>{formatAmountInr(taxableAmountIs)}</b>
                              </td>
                            </tr>
                            {taxRateIs > 0 && (
                              <tr>
                                <th scope="row">
                                  {taxName} @ {taxRateIs}% :
                                </th>
                                <td>{formatAmountInr(taxAmount)}</td>
                              </tr>
                            )}
                            <tr>
                              <th scope="row">Total After Tax : </th>
                              <td>
                                <b>{formatAmountInr(finalInvoicePrice)}</b>
                              </td>
                            </tr>
                            {exchangeAmount > 0 && (
                              <tr>
                                <th scope="row">Exchange / Old Value :</th>
                                <td>
                                  <b className="text-danger">
                                    - {formatAmountInr(exchangeAmount)}
                                  </b>
                                </td>
                              </tr>
                            )}
                            <tr>
                              <th scope="row">GRAND TOTAL :</th>
                              <td>
                                <b className="baseColor">
                                  {formatNumberNoDacimal(grandTotalPrice)}
                                </b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 text-center">
                  <button
                    disabled={proceedOfButton}
                    type="submit"
                    className="btn btn-block baseBackGroundColor"
                  >
                    Update Sale & View Invoice
                  </button>
                </div>
              </form>
            ) : (
              <AccessDenide />
            )}
          </div>
          {/* End Page Title */}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default InvoiceOrderDetails;
