import React, { useEffect } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import AccessDenide from "../AccessDenide/AccessDenide";
import {
  formatAmountInr,
  authTokenHeader,
  ServiceURL,
  getFlooredFixed,
  formatNumberNoDacimal,
  SessionOut,
} from "../Utils/utils";
import Footer from "../Footer";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderShow from "../Loader/LoaderShow";

const AddNewOrderRetail = () => {
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);
  const [customerMobile, setCustomerMobile] = React.useState("");
  const [customerName, setCustomerName] = React.useState("");
  const [customerEmail, setCustomerEmail] = React.useState("");
  const [customerAddress, setCustomerAddress] = React.useState("");
  const customerId = Math.floor(10000000 * Math.random() + 99999999);

  const [allProducts, setAllProducts] = React.useState([
    {
      id: 0,
      stock_id: "",
      product_name: "",
      one_gram_price: 0,
      retail_gross_weight: 0,
      retail_net_weight: 0,
      retail_amount: 0,
      stones_amount: 0,
      retail_value_added: 0,
      retail_value_added_amount: 0,
      item_status: 0,
      total_amount: 0,
      no_of_grams: 0,
      wastage_grams: 0,
      melting_at: 0,
      purity_at: 0,
      making_amount: 0,
      stock_available_gms: 0,
    },
  ]);

  const [productInfoExtra, setProductInfoExtra] = React.useState("");
  const [inrAnyExtra, setInrAnyExtra] = React.useState(0);

  const [taxRateIs, setTaxRateIs] = React.useState(0);
  const [taxAmount, setTaxAmount] = React.useState(0);
  const [removeTaxAmount, setRemoveTaxAmount] = React.useState(false);
  const [amountTotal, setAmountTotal] = React.useState(0);

  const [taxName, setTaxName] = React.useState("GST");

  const [finalInvoicePrice, setFinalInvoicePrice] = React.useState(0);

  const [paymentMothod, setPaymentMothod] = React.useState("ND");
  const [totalPaidAmount, setTotalPaidAmount] = React.useState(0);
  const [totalDueAmount, setTotalDueAmount] = React.useState(0);

  const [discountType, setDiscountType] = React.useState("N");

  const [calculateButtonText, setCalculateButtonText] = React.useState(
    "Calculate Total & Final Price"
  );

  const [exchangeProductName, setExchangeProductName] = React.useState("");
  const [exchangeGramsWt, setExchangeGramsWt] = React.useState();
  const [exchangePurity, setExchangePurity] = React.useState();
  const [exchangeMelting, setExchangeMelting] = React.useState();
  const [exchangeNetWt, setExchangeNetWt] = React.useState();
  const [exchangePriceRateOnGram, setExchangePriceRateOnGram] =
    React.useState();
  const [exchangeAmount, setExchangeAmount] = React.useState(0);
  const [exchangeGrossWt, setExchangeGrossWt] = React.useState(0);

  const [grandTotalPrice, setGrandTotalPrice] = React.useState(0);

  const [proceedOfButton, setProceedOfButton] = React.useState(true);

  const [addExchangeItems, setAddExchangeItems] = React.useState(false);

  const [allStocks, setAllStocks] = React.useState([]);

  const [customerOldDues, setCustomerOldDues] = React.useState([]);

  const [totalAfterTax, setTotalAfterTax] = React.useState(0);

  const [listOfCustomers, setListOfCustomers] = React.useState([]);

  const [usersDataList, setUsersDataList] = React.useState([]);

  const [dataLoding, setDataLoading] = React.useState(false);

  const [discountRate, setDiscountRate] = React.useState(0);

  const [finalDiscountAmount, setFinalDiscountAmount] = React.useState(0);

  const token = authTokenHeader();
  const serviceURl = ServiceURL();
  const navigator = useNavigate();

  const [stockAvlblGrams, setStockAvlbl] = React.useState("");

  const getStockInfoById = (stockIdIs) => {
    const getStockDetails = async () => {
      const endpoint = serviceURl + `stock/${stockIdIs}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          user_header_key: token,
        },
      };
      await fetch(endpoint, options)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setStockAvlbl(data.results[0].stock_available);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              alert(data.message);
              navigator("/dashboard");
            }
          }
        });
    };
    getStockDetails();
  };

  useEffect(() => {
    const fetchngTaxDetails = async () => {
      setDataLoading(true);
      const taxURl = serviceURl + `tax/details/${16073045}`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json", user_header_key: token },
      };
      await fetch(taxURl, options)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setTaxName(data.results[0].tax_name);
            setTaxRateIs(data.results[0].tax_percentage);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              toast.error(data.message);
              toast.info("Contact Developer @ 8074725033");
              navigator("/dashboard");
            }
          }
          setDataLoading(false);
        });
    };
    const GetListOfStocks = async () => {
      setDataLoading(true);
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json", user_header_key: token },
      };
      const endpoint = serviceURl + "stock/stocklist";
      await fetch(endpoint, options)
        .then((res) => res.json())
        .then((data) => {
          if (data.success === false) {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              toast.error(data.message);
            }
          } else {
            setAllStocks(data.results);
          }
          setDataLoading(false);
        });
    };
    const getCustomerList = async () => {
      setDataLoading(true);
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json", user_header_key: token },
      };
      const endpoint = serviceURl + "account/customers";
      await fetch(endpoint, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setListOfCustomers(data.results);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              toast.error(data.message);
            }
          }
          setDataLoading(false);
        });
    };

    GetListOfStocks();
    fetchngTaxDetails();
    getCustomerList();
  }, []);

  const getExistingCustomerDetails = async () => {
    const customerURl = serviceURl + `account/customer/${customerMobile}`;
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json", user_header_key: token },
    };
    await fetch(customerURl, options)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setCustomerMobile(data.results[0].user_mobile);
          setCustomerName(data.results[0].user_name);
          setCustomerEmail(data.results[0].user_email);
          setCustomerAddress(data.results[0].user_address);
          setGettingDetailsButton("Get User Details");
          toast.success(data.message);
        } else {
          if (data.message === "invalidToken") {
            SessionOut("invalidToken");
          } else if (data.message === "internalServerError") {
            SessionOut("internalServerError");
          } else {
            toast.error(data.message);
            setGettingDetailsButton("Get User Details");
          }
        }
      })
      .then(() => {
        getOldDuesOfSameCustomer(customerMobile);
      });
  };

  const getOldDuesOfSameCustomer = async (custMobile) => {
    const customerURl = serviceURl + `sales/due/getdues`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json", user_header_key: token },
      body: JSON.stringify({
        search_input: custMobile,
      }),
    };
    await fetch(customerURl, options)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setCustomerOldDues(data.results);
        } else {
          if (data.message === "invalidToken") {
            SessionOut("invalidToken");
          } else if (data.message === "internalServerError") {
            SessionOut("internalServerError");
          } else {
            toast.error(data.message);
          }
        }
      });
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    const createOrder = {
      user_id: customerId,
      user_name: customerName,
      user_mobile: customerMobile,
      user_email: customerEmail,
      user_address: customerAddress,

      extra_product_desc: productInfoExtra,
      extra_product_amount: inrAnyExtra,

      cart_products: allProducts,

      exchange_products_name: exchangeProductName,
      exchange_rate_one_gram: exchangePriceRateOnGram,
      exchange_grams_weight: exchangeGramsWt,
      exchange_melting_at: exchangeMelting,
      exchange_purity_at: exchangePurity,
      exchange_net_weight: exchangeNetWt,
      exchange_total_amount: exchangeAmount,
      exchange_gross_weight: exchangeGrossWt,

      payment_method: paymentMothod,

      order_total_amount: amountTotal,
      order_discount_amount: finalDiscountAmount,
      tax_rate_at: taxRateIs,
      order_tax_total_amount: taxAmount,
      order_total_after_tax: totalAfterTax,
      order_grand_total: grandTotalPrice,
      paid_amount: totalPaidAmount,
      txn_paid_amount: totalPaidAmount,
      due_amount: totalDueAmount,
      order_type: "R",
    };
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json", user_header_key: token },
      body: JSON.stringify(createOrder),
    };
    const endpoint = serviceURl + "sales/create";

    await fetch(endpoint, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert(`${data.message}, Opening Invoice...`);
          navigator(`/invoice-order-view/${data.sale_id}`);
        } else {
          if (data.message === "invalidToken") {
            SessionOut("invalidToken");
          } else if (data.message === "internalServerError") {
            SessionOut("internalServerError");
          } else {
            toast.error(data.message);
          }
        }
      });
  };

  const handleInputChange = (e, i) => {
    const name = e.target.name;
    const value =
      e.target.type === "number" ? parseFloat(e.target.value) : e.target.value;
    const onChangeValue = [...allProducts];
    onChangeValue[i][name] = value;
    if (name === "stock_id") {
      onChangeValue[i]["product_name"] = allStocks.filter(
        (x) => x.stock_id === e.target.value
      )[0]?.stock_name;
    }
    const noOfGramsInput = onChangeValue[i]["no_of_grams"];
    if (noOfGramsInput > stockAvlblGrams) {
      toast.error("No Of Grams Are Grater Then Stock Available, Not Allowed!");
      toast.success("Stock Available Grams : " + stockAvlblGrams);
      return false;
    } else {
      if (onChangeValue[i]["no_of_grams"]) {
        onChangeValue[i]["stock_available_gms"] = getFlooredFixed(
          stockAvlblGrams - onChangeValue[i]["no_of_grams"],
          3
        );
      }
    }

    onChangeValue[i][name] = value;

    if (name === "stock_id") {
      onChangeValue[i]["product_name"] = allStocks.filter(
        (x) => x.stock_id === e.target.value
      )[0]?.stock_name;
    }
    const oneGramPrice = onChangeValue[i]["one_gram_price"];
    const netWt = onChangeValue[i]["retail_net_weight"];
    const stonesAmount = onChangeValue[i]["stones_amount"];
    const valueAddedPer = onChangeValue[i]["retail_value_added"];

    const calAmount = netWt * oneGramPrice;
    onChangeValue[i]["retail_amount"] = calAmount;

    const calVa = (calAmount * valueAddedPer) / 100;
    onChangeValue[i]["retail_value_added_amount"] = calVa;

    onChangeValue[i]["total_amount"] = calAmount + calVa + stonesAmount;

    setAllProducts(onChangeValue);
    setCalculateButtonText("Re-Calculate Now");
    setProceedOfButton(true);
  };

  const handleAddNewProduct = () => {
    setAllProducts([
      ...allProducts,
      {
        id: 0,
        stock_id: "",
        product_name: "",
        one_gram_price: 0,
        retail_gross_weight: 0,
        retail_net_weight: 0,
        retail_amount: 0,
        stones_amount: 0,
        retail_value_added: 0,
        retail_value_added_amount: 0,
        item_status: 0,
        total_amount: 0,
        no_of_grams: 0,
        wastage_grams: 0,
        melting_at: 0,
        purity_at: 0,
        making_amount: 0,
        stock_available_gms: 0,
      },
    ]);
    setCalculateButtonText("Re-Calculate Now");
    setProceedOfButton(true);
  };

  const handleRemoveProduct = (i) => {
    const deleteValue = [...allProducts];
    deleteValue.splice(i, 1);
    setAllProducts(deleteValue);
    setCalculateButtonText("Re-Calculate Now");
    setProceedOfButton(true);
  };

  const calculateFinalPrice = () => {
    const totalAmountProducts = allProducts.reduce((accumulator, object) => {
      return accumulator + object.total_amount;
    }, 0);
    let totalAmountIs = 0;
    totalAmountIs = getFlooredFixed(
      Number(totalAmountProducts) + Number(inrAnyExtra),
      3
    );
    let discountAmount = 0;
    if (discountType === "P") {
      discountAmount = getFlooredFixed((totalAmountIs * discountRate) / 100, 3);
    } else if (discountType === "F") {
      discountAmount = getFlooredFixed(discountRate, 3);
    } else {
      discountAmount = 0;
    }
    setFinalDiscountAmount(discountAmount);
    totalAmountIs = getFlooredFixed(
      Number(totalAmountIs) - Number(discountAmount),
      3
    );
    const totalAmountProductsNow = getFlooredFixed(totalAmountProducts, 3);
    setAmountTotal(totalAmountProductsNow);
    let taxAmountIs = 0;
    if (!removeTaxAmount) {
      taxAmountIs = (totalAmountIs * taxRateIs) / 100;
    }
    setTaxAmount(getFlooredFixed(taxAmountIs, 3));
    const finalInvoicePriceIs = Number(totalAmountIs) + Number(taxAmountIs);
    setFinalInvoicePrice(getFlooredFixed(finalInvoicePriceIs, 3));
    setTotalAfterTax(getFlooredFixed(finalInvoicePriceIs, 3));
    const grandTotal = Number(finalInvoicePriceIs) - Number(exchangeAmount);
    setGrandTotalPrice(Math.round(grandTotal));
    setTotalDueAmount(Math.round(grandTotal));
    setCalculateButtonText("Calculate Total & Final Price");
    toast.info("Calculated Successfully");
    setProceedOfButton(false);
  };

  const calculateTotalDueAmount = (e) => {
    setTotalPaidAmount(e.target.value);
    const totalDueIs = grandTotalPrice - e.target.value;
    setTotalDueAmount(getFlooredFixed(totalDueIs, 0));
  };

  const clearTotalAmount = () => {
    setAmountTotal(0);
    setTaxAmount(0);
    setFinalInvoicePrice(0);
    setPaymentMothod("ND");
    setTotalDueAmount(0);
    setTotalPaidAmount(0);
    setGrandTotalPrice(0);
    setCalculateButtonText("Re-Calculate Now");
    setProceedOfButton(true);
  };

  const [gettingDetailsButton, setGettingDetailsButton] =
    React.useState("Get User Details");
  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"addOrderRetail"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Add New Sale : Retail</h1>
            <hr />
            {accessOnComponent ? (
              dataLoding ? (
                <LoaderShow />
              ) : (
                <form onSubmit={handelSubmit}>
                  <div className="row">
                    <div className="col-md-10 mb-2">
                      <div className="form-group">
                        <input
                          type="text"
                          list="userNameSuggestion"
                          className="form-control"
                          id="mobileNumber"
                          placeholder="Enter Customer Name/Mobile Number (Mobile as primary)"
                          value={customerMobile}
                          required
                          onChange={(e) => {
                            setCustomerMobile(e.target.value);
                            let userSyncList = listOfCustomers.filter(
                              (x) =>
                                x.user_name
                                  .toLowerCase()
                                  .includes(e.target.value.toLowerCase()) ||
                                x.user_mobile.includes(e.target.value)
                            );
                            setUsersDataList(userSyncList);
                          }}
                        />
                        <datalist id="userNameSuggestion">
                          {usersDataList.map((user, i) => (
                            <option key={i} value={user.user_mobile}>
                              {user.user_name}
                            </option>
                          ))}
                        </datalist>
                      </div>
                      <small className="text-center">
                        Note : Re-verify customer mobile number, may not be
                        valid OR add OTP future to make secure sale.
                      </small>
                    </div>
                    <div className="col-md-2 mb-2">
                      <button
                        type="button"
                        onClick={() => {
                          setGettingDetailsButton("Please Wait...");
                          getExistingCustomerDetails();
                        }}
                        className="btn baseBackGroundColor btn-block"
                      >
                        {gettingDetailsButton}
                      </button>
                    </div>
                  </div>
                  <div className="card mt-3">
                    <div className="card-body mb-2">
                      <h5 className="card-title">Customer Details : </h5>
                      <div className="row">
                        <div className="form-group col-md-6 mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="fullName"
                            placeholder="Customer Name"
                            autoComplete="off"
                            value={customerName}
                            required
                            onChange={(e) => {
                              setCustomerName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group col-md-6 mb-3">
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Customer Email (Optional)"
                            autoComplete="off"
                            value={customerEmail}
                            onChange={(e) => {
                              setCustomerEmail(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control mb-1"
                          id="inputAddress"
                          placeholder="Customer Address : 1234 Main St"
                          autoComplete="off"
                          value={customerAddress}
                          onChange={(e) => {
                            setCustomerAddress(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Product Details : </h5>
                      {allProducts.map((value, i) => {
                        return (
                          <div
                            className="border border-primary rounded p-3 mb-3 shadow"
                            id="orderDivIs"
                            key={i}
                          >
                            <div className="row">
                              <div className="form-group col-md-4 mb-3">
                                <div class="input-group input-group-sm">
                                  <div class="input-group-prepend">
                                    <label
                                      class="input-group-text"
                                      for="inputGroupSelect01"
                                    >
                                      Select Stock :
                                    </label>
                                  </div>
                                  <select
                                    class="custom-select"
                                    name="stock_id"
                                    id="stock_id"
                                    value={value.stock_id}
                                    onChange={(e) => {
                                      handleInputChange(e, i);
                                      getStockInfoById(e.target.value);
                                    }}
                                    required
                                  >
                                    <option value="">Choose...</option>
                                    {allStocks.map((value, i) => {
                                      return (
                                        <option key={i} value={value.stock_id}>
                                          {value.stock_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                              <div className="form-group col-md-4 input-group-sm mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="product_name"
                                  name="product_name"
                                  placeholder="Product Name"
                                  autoComplete="off"
                                  value={value.product_name}
                                  required
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </div>
                              <div className="form-group col-md-4">
                                <div className="input-group input-group-sm">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="basic-grams"
                                    >
                                      1 Gram Price (INR):
                                    </span>
                                  </div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="one_gram_price"
                                    name="one_gram_price"
                                    placeholder="(eg. 5650.75)"
                                    autoComplete="off"
                                    min="1"
                                    step="any"
                                    value={value.one_gram_price}
                                    required
                                    onChange={(e) => handleInputChange(e, i)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col-md-4">
                                <div className="input-group input-group-sm">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="basic-grams"
                                    >
                                      Gross Weight:
                                    </span>
                                  </div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="retail_gross_weight"
                                    name="retail_gross_weight"
                                    placeholder="(eg. 12.75)"
                                    autoComplete="off"
                                    step="any"
                                    value={
                                      value.retail_gross_weight === 0
                                        ? ""
                                        : value.retail_gross_weight
                                    }
                                    required
                                    onChange={(e) => handleInputChange(e, i)}
                                  />
                                </div>
                              </div>
                              <div className="form-group col-md-4">
                                <div className="input-group input-group-sm">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="basic-symbol"
                                    >
                                      Net Weight:
                                    </span>
                                  </div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="retail_net_weight"
                                    name="retail_net_weight"
                                    placeholder="(eg. 12.75)"
                                    value={
                                      value.retail_net_weight === 0
                                        ? ""
                                        : value.retail_net_weight
                                    }
                                    autoComplete="off"
                                    step="any"
                                    required
                                    onChange={(e) => handleInputChange(e, i)}
                                  />
                                </div>
                              </div>
                              <div className="form-group col-md-4">
                                <div className="input-group input-group-sm">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="basic-symbol"
                                    >
                                      VA @ % :
                                    </span>
                                  </div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="retail_value_added"
                                    name="retail_value_added"
                                    placeholder="Value Added (eg. 95)"
                                    step="any"
                                    value={
                                      value.retail_value_added === 0
                                        ? ""
                                        : value.retail_value_added
                                    }
                                    onChange={(e) => handleInputChange(e, i)}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col-md-4">
                                <div className="input-group input-group-sm">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="basic-grams"
                                    >
                                      VA Amount (INR):
                                    </span>
                                  </div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="retail_value_added_amount"
                                    name="retail_value_added_amount"
                                    placeholder="0.00"
                                    autoComplete="off"
                                    min="0"
                                    step="any"
                                    value={
                                      value.retail_value_added_amount === 0
                                        ? ""
                                        : value.retail_value_added_amount
                                    }
                                    onChange={(e) => handleInputChange(e, i)}
                                    readOnly={true}
                                  />
                                </div>
                              </div>
                              <div className="form-group col-md-4">
                                <div className="input-group input-group-sm">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="basic-grams"
                                    >
                                      Stones (INR):
                                    </span>
                                  </div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="stones_amount"
                                    name="stones_amount"
                                    placeholder="Amount (eg. 1200)"
                                    autoComplete="off"
                                    min="0"
                                    step="any"
                                    value={
                                      value.stones_amount === 0
                                        ? ""
                                        : value.stones_amount
                                    }
                                    onChange={(e) => handleInputChange(e, i)}
                                  />
                                </div>
                              </div>
                              <div className="form-group col-md-4">
                                <div className="input-group input-group-sm">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="basic-symbol"
                                    >
                                      Item Status:
                                    </span>
                                  </div>
                                  <select
                                    class="form-control"
                                    id="item_status"
                                    name="item_status"
                                    required
                                    defaultValue={value.item_status}
                                    onChange={(e) => handleInputChange(e, i)}
                                  >
                                    <option value="0">
                                      Item Paid ( Given )
                                    </option>
                                    <option value="1">
                                      Item Due ( Pending )
                                    </option>
                                  </select>
                                  {/* <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Amount (eg. 1600)"
                                  id="stones_amount"
                                  name="stones_amount"
                                  min="0"
                                  step="any"
                                  value={value.stones_amount}
                                  onChange={(e) => handleInputChange(e, i)}
                                  readOnly={inputHandleDisable ? true : false}
                                /> */}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="input-group input-group-sm">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="basic-symbol"
                                    >
                                      Total (INR):
                                    </span>
                                  </div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="0.00"
                                    id="sub_total_amount"
                                    name="sub_total_amount"
                                    min="0"
                                    value={
                                      value.total_amount === 0
                                        ? ""
                                        : value.total_amount
                                    }
                                    onChange={(e) => handleInputChange(e, i)}
                                    required
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 text-center my-auto">
                                <b>
                                  Product : <g className="text-info">{i + 1}</g>
                                </b>
                              </div>
                              <div className="col-md-4 text-center my-auto">
                                {allProducts.length === 1 ? (
                                  <>--</>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={() => handleRemoveProduct(i)}
                                    className="btn btn-sm btn-danger"
                                  >
                                    <i class="bi bi-trash-fill"></i>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={handleAddNewProduct}
                      >
                        + Add Another Product
                      </button>
                      <hr></hr>
                      <div
                        className="accordion shadow border border-info"
                        id="accordionExchange"
                      >
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                              onClick={() =>
                                setAddExchangeItems(!addExchangeItems)
                              }
                            >
                              <b className="mr-1">+</b> Add -{" "}
                              <b className="ml-1 mr-1">Old / Exchange </b> - If
                              Any
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className={`accordion-collapse collapse ${
                              addExchangeItems ? "show" : ""
                            }`}
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExchange"
                          >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-md-6 mb-3">
                                  <div className="input-group input-group-sm">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="basic-symbol"
                                      >
                                        Ex. Product Name:
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Exchange Product Name"
                                      aria-label="Exchnage"
                                      aria-describedby="basic-symbol"
                                      value={exchangeProductName}
                                      onChange={(e) => {
                                        setExchangeProductName(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                  <div className="input-group input-group-sm">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="basic-symbol"
                                      >
                                        Exchange Rate On Gram (INR):
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="(eg. 4500)"
                                      aria-label="Exchnage"
                                      aria-describedby="basic-symbol"
                                      step="any"
                                      min="0"
                                      value={exchangePriceRateOnGram}
                                      onChange={(e) => {
                                        setExchangePriceRateOnGram(
                                          e.target.value !== 0
                                            ? e.target.value
                                            : 0
                                        );
                                        clearTotalAmount();
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                  <div className="input-group input-group-sm">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="basic-symbol"
                                      >
                                        Gross WT:
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="(eg. 0.900)"
                                      aria-label="Exchnage"
                                      aria-describedby="basic-symbol"
                                      step="any"
                                      min="0"
                                      value={exchangeGrossWt}
                                      onChange={(e) => {
                                        setExchangeGrossWt(
                                          e.target.value !== 0
                                            ? e.target.value
                                            : 0
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                  <div className="input-group input-group-sm">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="basic-symbol"
                                      >
                                        Net. WT:
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="(eg. 7.50)"
                                      aria-label="Exchnage"
                                      aria-describedby="basic-symbol"
                                      step="any"
                                      min="0"
                                      required
                                      value={exchangeNetWt}
                                      onChange={(e) => {
                                        setExchangeNetWt(
                                          e.target.value !== 0
                                            ? e.target.value
                                            : 0
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                  <div className="input-group input-group-sm">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="basic-symbol"
                                      >
                                        % @ :
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="(eg. 75)"
                                      aria-label="Exchnage"
                                      aria-describedby="basic-symbol"
                                      step="any"
                                      min="0"
                                      value={exchangeMelting}
                                      onChange={(e) => {
                                        setExchangeMelting(
                                          e.target.value !== 0
                                            ? e.target.value
                                            : 0
                                        );
                                        const MakePurity =
                                          exchangeNetWt *
                                          (e.target.value / 100);
                                        setExchangePurity(
                                          getFlooredFixed(MakePurity, 3)
                                        );
                                        const totalAMountExchange =
                                          getFlooredFixed(
                                            MakePurity *
                                              exchangePriceRateOnGram,
                                            3
                                          );
                                        setExchangeAmount(totalAMountExchange);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                  <div className="input-group input-group-sm">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="basic-symbol"
                                      >
                                        Purity ( In % ):
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="(eg. 0.675)"
                                      aria-label="Exchnage"
                                      aria-describedby="basic-symbol"
                                      step="any"
                                      min="0"
                                      value={exchangePurity}
                                      onChange={(e) => {
                                        setExchangePurity(
                                          e.target.value !== 0
                                            ? e.target.value
                                            : 0
                                        );
                                      }}
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                  <div className="input-group input-group-sm">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="basic-symbol"
                                      >
                                        Exchange Amount (INR):
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="(eg. 12000)"
                                      aria-label="Exchnage"
                                      aria-describedby="basic-symbol"
                                      step="any"
                                      min="0"
                                      value={exchangeAmount}
                                      onChange={(e) => {
                                        setExchangeAmount(
                                          e.target.value !== 0
                                            ? e.target.value
                                            : 0
                                        );
                                        clearTotalAmount();
                                      }}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="2"
                              placeholder="Enter If Any Extra... (eg. somthing)"
                              onChange={(e) => {
                                setProductInfoExtra(e.target.value);
                              }}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-6 my-auto">
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-symbol"
                              >
                                Extra Amount (INR):
                              </span>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Amount (eg. 5500)"
                              aria-label="TotalAmount"
                              aria-describedby="basic-symbol"
                              min="0"
                              value={inrAnyExtra}
                              onChange={(e) => {
                                setInrAnyExtra(
                                  parseInt(
                                    e.target.value !== 0 ? e.target.value : 0
                                  )
                                );
                                clearTotalAmount();
                              }}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-symbol"
                              >
                                Add Discount:
                              </span>
                            </div>
                            <select
                              className="form-select"
                              onChange={(e) => {
                                setDiscountType(e.target.value);
                              }}
                            >
                              <option value="N">-- NO Discount --</option>
                              <option value="F">
                                Fixed Amount (FLAT Amount)
                              </option>
                              <option value="P">Percentage (%)</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-symbol"
                              >
                                Discount{" "}
                                {`${
                                  discountType === "F"
                                    ? "(FLAT - INR)"
                                    : discountType === "P"
                                    ? "(FLAT IN %)"
                                    : ""
                                }`}
                                :
                              </span>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              placeholder={
                                discountType === "F"
                                  ? "eg. 1000"
                                  : discountType === "P"
                                  ? "eg. 10"
                                  : "Discount Amount / Percentage"
                              }
                              onChange={(e) => {
                                setDiscountRate(parseInt(e.target.value));
                                clearTotalAmount();
                              }}
                              defaultValue={0}
                              min="0"
                              max={discountType === "P" ? 100 : null}
                              value={discountType === "N" ? 0 : discountRate}
                              disabled={discountType === "N" ? true : false}
                              aria-label="TotalAmount"
                              aria-describedby="basic-symbol"
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-6 my-auto text-center">
                          {customerOldDues.length > 0 ? (
                            <Link
                              to={`/customerdues/${customerMobile}`}
                              target="_blank"
                              type="button"
                              className="btn btn-sm btn-info"
                            >
                              <b>Old Dues</b> of This Customer -{" "}
                              <b>{customerOldDues.length}</b>
                            </Link>
                          ) : null}
                          <div
                            className="modal fade"
                            id="exampleModal"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog" role="document">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="exampleModalLabel"
                                  >
                                    Modal title
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div className="modal-body">...</div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr></hr>
                          <button
                            type="button"
                            onClick={calculateFinalPrice}
                            className="btn baseBackGroundColor btn-block"
                          >
                            {calculateButtonText}
                          </button>
                          <br />
                          <small>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  value=""
                                  onChange={(e) => {
                                    setRemoveTaxAmount(e.target.checked);
                                    clearTotalAmount();
                                  }}
                                />
                                <b>Remove GST from this SALE!</b>
                              </label>
                            </div>
                          </small>
                          <hr />
                          <small>
                            Note :{" "}
                            <b className="baseColor">
                              Recaculate is must on every change
                            </b>
                          </small>
                        </div>
                        <div className="col-md-6">
                          <table className="table table-hover text-center mt-3">
                            <tbody>
                              <tr>
                                <th scope="row">Total Amount : </th>
                                <td>
                                  <b>{formatAmountInr(amountTotal)}</b>
                                </td>
                              </tr>
                              {inrAnyExtra > 0 && (
                                <tr>
                                  <th scope="row">Extra (Other) :</th>
                                  <td>{formatAmountInr(inrAnyExtra)}</td>
                                </tr>
                              )}
                              {finalDiscountAmount > 0 && (
                                <tr>
                                  <th scope="row">Discount :</th>
                                  <td>
                                    - {formatAmountInr(finalDiscountAmount)}
                                  </td>
                                </tr>
                              )}
                              {taxRateIs > 0 && (
                                <tr>
                                  <th scope="row">
                                    {taxName} @ {taxRateIs}% :
                                  </th>
                                  <td>{formatAmountInr(taxAmount)}</td>
                                </tr>
                              )}
                              <tr>
                                <th scope="row">Total After Tax : </th>
                                <td>
                                  <b>{formatAmountInr(finalInvoicePrice)}</b>
                                </td>
                              </tr>
                              {exchangeAmount > 0 && (
                                <tr>
                                  <th scope="row">Exchange / Old Value :</th>
                                  <td>
                                    <b className="text-danger">
                                      - {formatAmountInr(exchangeAmount)}
                                    </b>
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <th scope="row">GRAND TOTAL :</th>
                                <td>
                                  <b className="baseColor">
                                    ₹{formatNumberNoDacimal(grandTotalPrice)}
                                  </b>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body mb-1">
                      <h5 className="card-title">
                        Payment Method & Paid Details :{" "}
                      </h5>
                      <div className="row">
                        <div className="col-md-12 mb-4">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-symbol"
                              >
                                <b className="text-black">Paid By</b>:
                              </span>
                            </div>
                            <select
                              className="form-select"
                              required
                              onChange={(e) => {
                                setPaymentMothod(e.target.value);
                              }}
                              value={paymentMothod}
                            >
                              <option value="ND" selected>
                                No Decision
                              </option>
                              <option value="C">Cash</option>
                              <option value="U">UPI App(Phonepe/Gpay)</option>
                              {/* <option value="O">
                              Online Payment - Via Payment Link
                            </option> */}
                            </select>
                          </div>
                          <small>
                            <b>Note</b> : If{" "}
                            <b className="baseColor">Online Payment</b>{" "}
                            Selected, then payment link will share to customer{" "}
                            <b className="baseColor">(via RazorPay)</b> ,{" "}
                            <b className="baseColor">
                              2.2% Extra on Grand TOTAL :{" "}
                              {formatAmountInr(
                                parseInt(grandTotalPrice * 2.2) / 100 +
                                  grandTotalPrice
                              )}{" "}
                            </b>
                          </small>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="input-group mb-2">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-symbol"
                              >
                                <b className="text-success">
                                  Paid Amount (INR)
                                </b>
                                :
                              </span>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Amount (eg. 5500)"
                              aria-label="TotalAmount"
                              aria-describedby="basic-symbol"
                              step="any"
                              min="0"
                              required
                              value={totalPaidAmount}
                              onChange={calculateTotalDueAmount}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input-group mb-2">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-symbol"
                              >
                                <b className="text-danger">Due Amount (INR):</b>
                              </span>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Amount (eg. 5500)"
                              aria-label="TotalAmount"
                              aria-describedby="basic-symbol"
                              step="any"
                              min="0"
                              required
                              readOnly
                              value={totalDueAmount}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 text-center">
                          <button
                            disabled={proceedOfButton}
                            type="submit"
                            className="btn btn-block baseBackGroundColor"
                          >
                            Save Sale & View
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )
            ) : (
              <AccessDenide />
            )}
          </div>
          {/* End Page Title */}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default AddNewOrderRetail;
