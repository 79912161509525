import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../Header";
import SideBar from "../SideBar";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import AccessDenide from "../AccessDenide/AccessDenide";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Footer from "../Footer";
import {
  ServiceURL,
  authTokenHeader,
  todayDateString,
  SessionOut,
} from "../Utils/utils";
import { useNavigate } from "react-router-dom";
import LoaderShow from "../Loader/LoaderShow";

const UpdateExpanseDetails = () => {
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);
  let params = useParams();
  const expanseId = params.id;
  const navigator = useNavigate();
  const token = authTokenHeader();
  const serviceurl = ServiceURL();

  const [dataLoading, setDataLoading] = React.useState(false);

  const [expanseIdDb, setExpanseIdDb] = React.useState("");
  const [expanseByName, setExpanseByName] = React.useState("");
  const [expanseByNumber, setExpanseByNumber] = React.useState("");
  const [expanseType, setExpanseType] = React.useState("");
  const [expanseAmount, setExpanseAmount] = React.useState("");
  const [expanseReason, setExpanseReason] = React.useState("");

  useEffect(() => {
    const getExpanseDetails = async () => {
      setDataLoading(true);
      const endpoint = serviceurl + `expanses/${expanseId}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          user_header_key: token,
        },
      };
      await fetch(endpoint, options)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setExpanseIdDb(data.details.expanse_id);
            setExpanseByName(data.details.expanse_by_name);
            setExpanseByNumber(data.details.expanse_by_mobile_no);
            setExpanseType(data.details.expanse_type);
            setExpanseAmount(data.details.expanse_amount);
            setExpanseReason(data.details.expanse_reason);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              alert(data.message);
              navigator("/list-expanses");
            }
          }
          setDataLoading(false);
        });
    };
    getExpanseDetails();
  }, []);

  const updateExpanse = async (e) => {
    e.preventDefault();
    const addExanseObject = {
      expanse_name: expanseByName,
      expanse_mobile: expanseByNumber,
      expanse_type: expanseType,
      expanse_amount: expanseAmount,
      expanse_reason: expanseReason,
    };

    let options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        user_header_key: token,
      },
      body: JSON.stringify(addExanseObject),
    };
    const endpoint = serviceurl + `expanses/update/${expanseId}`;
    await fetch(endpoint, options)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert(data.message);
        } else {
          if (data.message === "invalidToken") {
            alert("Lost Session!, Login Again!");
            navigator("/login");
          } else if (data.message === "internalServerError") {
            alert("Internal Server Error!, Login Again!");
            navigator("/login");
          } else {
            alert(data.message);
          }
        }
        navigator("/list-expanses");
      });
  };
  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"updateExpanse"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Update Expanse</h1>
            <hr />
          </div>
          {accessOnComponent ? (
            <section className="section dashboard">
              <>
                <div className="card mb-4">
                  <div className="card-body">
                    <h5 className="card-title">
                      Update Expanse | #{expanseIdDb}{" "}
                      <span>| {todayDateString(new Date())}</span>
                    </h5>
                    {dataLoading ? (
                      <LoaderShow />
                    ) : (
                      <>
                        <form onSubmit={updateExpanse}>
                          <div className="row mt-1">
                            <div className="col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  placeholder="Enter Name By"
                                  value={expanseByName}
                                  onChange={(e) =>
                                    setExpanseByName(e.target.value)
                                  }
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <input
                                  type="number"
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  placeholder="Enter Mobile number By"
                                  value={expanseByNumber}
                                  onChange={(e) =>
                                    setExpanseByNumber(e.target.value)
                                  }
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <input
                                  type="number"
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  placeholder="Enter Amount"
                                  value={expanseAmount}
                                  onChange={(e) =>
                                    setExpanseAmount(e.target.value)
                                  }
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <select
                                className="form-select"
                                value={expanseType}
                                onChange={(e) => setExpanseType(e.target.value)}
                                required
                              >
                                <option selected>Select Expanse Type</option>
                                <option value="D">Debit (-)</option>
                                <option value="C">Credit (+)</option>
                              </select>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <textarea
                                  className="form-control"
                                  rows="2"
                                  placeholder="Enter Reason For Expanse"
                                  value={expanseReason}
                                  onChange={(e) =>
                                    setExpanseReason(e.target.value)
                                  }
                                  required
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <button
                            type="submit"
                            className="mt-1 btn baseBackGroundColor btn-block"
                          >
                            Update Now
                          </button>
                        </form>
                      </>
                    )}
                  </div>
                </div>
              </>
            </section>
          ) : (
            <AccessDenide />
          )}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default UpdateExpanseDetails;
