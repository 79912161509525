import React, { useEffect } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import AccessDenide from "../AccessDenide/AccessDenide";
import LoaderShow from "../Loader/LoaderShow";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import {
  ServiceURL,
  authTokenHeader,
  formatDateDyMmYyyy,
  SessionOut,
} from "../Utils/utils";

const ListOfWorkShopData = () => {
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);

  const [dataLoding, setDataLoading] = React.useState(false);
  const token = authTokenHeader();
  const serviceUrl = ServiceURL();

  const [workShop, setWorkShop] = React.useState([]);

  useEffect(() => {
    const fetchingWorkShop = async () => {
      setDataLoading(true);
      const options = {
        method: "Get",
        headers: { "Content-Type": "application/json", user_header_key: token },
      };
      const endpoint = serviceUrl + "workdata/list";
      await fetch(endpoint, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setWorkShop(data.results);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              alert(data.message);
            }
          }
          setDataLoading(false);
        });
    };
    fetchingWorkShop();
  }, []);

  $(document).ready(function () {
    setTimeout(function () {
      $("#listOfAllWorks").DataTable();
    }, 1500);
  });

  const findRateDiff = (provided, received) => {
    if (provided > received) {
      return (
        <>
          <small>
            <b className="text-danger"> - {(provided - received).toFixed(2)}</b>
          </small>
        </>
      );
    } else if (provided < received) {
      return (
        <>
          <small>
            <b className="text-success">
              {" "}
              + {(received - provided).toFixed(2)}
            </b>
          </small>
        </>
      );
    } else {
      return 0;
    }
  };

  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"allWorks"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>List Of All Works</h1>
            <hr />
          </div>
          {accessOnComponent ? (
            <section className="section dashboard">
              <>
                <div className="card mb-4">
                  <div className="card-body">
                    <h5 className="card-title">All Works</h5>
                    {dataLoding ? (
                      <LoaderShow />
                    ) : (
                      <>
                        <div className="mt-2">
                          <div class="table-responsive">
                            <table
                              className="table  datatable text-center"
                              id="listOfAllWorks"
                            >
                              <thead>
                                <tr>
                                  <th className="text-center" scope="col">
                                    #
                                  </th>
                                  <th className="text-center" scope="col">
                                    WorksShopName
                                  </th>
                                  <th className="text-center" scope="col">
                                    Provided.WT
                                  </th>
                                  <th className="text-center" scope="col">
                                    Received.WT
                                  </th>
                                  <th className="text-center" scope="col">
                                    WT.Diff
                                  </th>
                                  <th className="text-center" scope="col">
                                    Status
                                  </th>
                                  <th className="text-center" scope="col">
                                    CreatedDate
                                  </th>
                                  <th className="text-center" scope="col">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {!workShop ? (
                                  <>
                                    <b>No Data</b>
                                  </>
                                ) : (
                                  <>
                                    {workShop.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <th scope="row">
                                            #{item.workshop[0]?.workshop_id}
                                          </th>
                                          <td>
                                            <Link
                                              to="#"
                                              title={
                                                item.workshop[0]?.workshop_name
                                              }
                                            >
                                              {item.workshop[0]?.workshop_name}
                                            </Link>
                                          </td>
                                          <td>
                                            <b>{item.forwared_wt}</b>
                                          </td>
                                          <td>
                                            <b>{item.received_wt}</b>
                                          </td>
                                          <td>
                                            {findRateDiff(
                                              item.forwared_wt,
                                              item.received_wt
                                            )}
                                          </td>
                                          <td>
                                            {item.workdata_status === 1 ? (
                                              <span className="badge bg-success">
                                                Received
                                              </span>
                                            ) : (
                                              <span className="badge bg-warning">
                                                Pending
                                              </span>
                                            )}
                                          </td>
                                          <td>
                                            {formatDateDyMmYyyy(
                                              new Date(`${item.created}`)
                                            )}
                                          </td>
                                          <td>
                                            <Link
                                              style={{ marginRight: "12px" }}
                                              to={`/update-work/${item.workdata_id}`}
                                            >
                                              <i className="bi bi-pencil mr-1" />{" "}
                                            </Link>
                                            {userRole === "superAdmin" ? (
                                              <Link
                                                className="ml-1"
                                                to={`/delete-work/${item.workdata_id}`}
                                              >
                                                <i className="bi bi-trash" />
                                              </Link>
                                            ) : null}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            </section>
          ) : (
            <AccessDenide />
          )}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default ListOfWorkShopData;
