import React from "react";
import { Link } from "react-router-dom";
import { todayDateString } from "./Utils/utils";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Header = ({ userRole, userName }) => {
  const [showSideBar, setShowSideBar] = React.useState(true);

  const showSideBarFunc = () => {
    if (showSideBar === true) {
      document.body.classList.add("toggle-sidebar");
      setShowSideBar(false);
    } else {
      document.body.classList.remove("toggle-sidebar");
      setShowSideBar(true);
    }
  };

  const softwareExpireDays = localStorage.getItem("srjexpiredays");

  return (
    <>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between">
          <Link to="/dashboard" className="logo d-flex align-items-center">
            <span>SRJ Portal</span>
          </Link>
          <i
            className="bi bi-list toggle-sidebar-btn"
            onClick={showSideBarFunc}
          />
        </div>
        {/* End Logo */}

        <div className="search-bar ml-2" style={{ width: "100%" }}>
          <b style={{ color: "#4154F1" }}>{todayDateString(new Date())}</b> |{" "}
          {softwareExpireDays <= 15 ? (
            <>
              <b className="ml-2">
                <small>
                  <b>Action Required :</b>
                </small>
              </b>{" "}
              <small className="text-danger">
                <b>
                  Software Expire in {softwareExpireDays} days (Stops Working),
                  Renew it soon, Contact Developer.
                </b>
              </small>
            </>
          ) : (
            <>
              <b className="ml-2">
                <small>Updates Required :</small>
              </b>{" "}
              <small style={{ color: "#4154F1" }}>
                For security improvement Add{" "}
                <Link to={"/upgrade-new-version"}>
                  <b>Upgraded Futures</b>
                </Link>
                ... Contact Developer.
              </small>
            </>
          )}
        </div>

        {/* End Search Bar */}
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            {/* End Search Icon*/}
            <li className="nav-item dropdown pe-3">
              <Link
                className="nav-link nav-profile d-flex align-items-center pe-0"
                data-bs-toggle="dropdown"
              >
                <img
                  src="images/profile.png"
                  alt="Profile"
                  className="rounded-circle"
                />
                <span class="d-none d-md-block dropdown-toggle ps-2">
                  {userName}
                </span>
              </Link>
              {/* End Profile Iamge Icon */}
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li class="dropdown-header">
                  <h6>
                    <b className="baseColor">{userName}</b>
                  </h6>
                  <span>
                    <b>{userRole === "superAdmin" ? "Super Admin" : "Admin"}</b>
                  </span>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <Link
                    className="dropdown-item d-flex align-items-center"
                    to="/profile-settings"
                  >
                    <i className="bi bi-gear" />
                    <span>Profile / Account Settings</span>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link
                    className="dropdown-item d-flex align-items-center"
                    to="/logout"
                  >
                    <i className="bi bi-box-arrow-right" />
                    <span>Sign Out</span>
                  </Link>
                </li>
              </ul>
              {/* End Profile Dropdown Items */}
            </li>
            {/* End Profile Nav */}
          </ul>
        </nav>
        {/* End Icons Navigation */}
      </header>
      {/* End Header */}
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Header;
