import React, { useEffect } from "react";
import { ServiceURL, SessionOut } from "../Utils/utils";
import CheckAccess from "../Loader/CheckAccess";

export const RoleContext = React.createContext();

const PrivateRoute = ({ allowedRoles, children }) => {
  const [authAdminRole, setAuthAdminRole] = React.useState("");
  const [authAdminUserName, setAuthAdminUserName] = React.useState("");
  const [checkAuthLoading, setCheckAuthLoading] = React.useState(false);
  const tokenIs = localStorage.getItem("SRtoken");

  useEffect(() => {
    const fetchingAuth = async () => {
      setCheckAuthLoading(true);
      const UrlIs = ServiceURL();
      const endpoint = UrlIs + "account/auth";
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          user_header_key: tokenIs,
        },
      };
      await fetch(endpoint, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setAuthAdminRole(data.admin_role);
            setAuthAdminUserName(data.admin_name);
            setCheckAuthLoading(false);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              alert(data.message);
            }
            window.location.href = "/login";
          }
        });
    };
    fetchingAuth();
  }, []);
  if (checkAuthLoading) {
    return <CheckAccess />;
  } else {
    const forwardToComponent = {
      accessOnComponent: allowedRoles.includes(authAdminRole),
      userRole: authAdminRole,
      UserName: authAdminUserName,
    };
    return (
      <RoleContext.Provider value={forwardToComponent}>
        {children}
      </RoleContext.Provider>
    );
  }
};

export default PrivateRoute;
