import React from "react";
import LoaderShow from "../Loader/LoaderShow";
import { useNavigate } from "react-router-dom";

const Logout = ({ errMsg }) => {
  const navigate = useNavigate();
  localStorage.removeItem("SRtoken");
  if (errMsg) {
    alert(errMsg);
    setTimeout(() => {
      navigate("/");
    }, 0);
  } else {
    navigate("/");
  }
  return (
    <>
      <LoaderShow />
    </>
  );
};

export default Logout;
