import React, { useEffect } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import {
  formatAmountInr,
  authTokenHeader,
  ServiceURL,
  projectDetailsUrl,
  getFlooredFixed,
  formatNumberNoDacimal,
  SessionOut,
} from "../Utils/utils";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import AccessDenide from "../AccessDenide/AccessDenide";
import LoaderShow from "../Loader/LoaderShow";
import Footer from "../Footer";
import { useNavigate, useParams } from "react-router-dom";
import Logout from "./Logout";

const AccountSettings = () => {
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);
  const [dataLoding, setDataLoding] = React.useState(false);

  const [password1, setPassword1] = React.useState("");
  const [password2, setPassword2] = React.useState("");

  const [taxRateIs, setTaxRateIs] = React.useState(0);
  const [taxName, setTaxName] = React.useState("");

  const [adminEmail, setAdminEmail] = React.useState("");
  const [adminId, setAdminId] = React.useState("");

  const [projectDetails, setProjectDetails] = React.useState({});

  const token = authTokenHeader();
  const serviceURl = ServiceURL();
  const navigator = useNavigate();

  useEffect(() => {
    const fetchngTaxDetails = async () => {
      setDataLoding(true);
      const taxURl = serviceURl + `tax/details/${16073045}`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json", user_header_key: token },
      };
      await fetch(taxURl, options)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setTaxName(data.results[0].tax_name);
            setTaxRateIs(data.results[0].tax_percentage);
            setDataLoding(false);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              alert(data.message);
              alert("Contact Developer @ 8074725033");
              navigator("/dashboard");
            }
          }
        });
    };

    const fetchProfile = async () => {
      setDataLoding(true);
      const taxURl = serviceURl + `account/profile`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json", user_header_key: token },
      };
      await fetch(taxURl, options)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setAdminEmail(data.admin_email);
            setAdminId(data.admin_id);
            setDataLoding(false);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              alert(data.message);
              alert("Contact Developer @ 8074725033");
              navigator("/dashboard");
            }
          }
        });
    };

    const fetchProjectDetails = async () => {
      setDataLoding(true);
      const fetchProjectDetailsUrl = projectDetailsUrl();
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      await fetch(fetchProjectDetailsUrl, options)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setProjectDetails(data);
            localStorage.setItem("srjexpiredays", data.days_left);
            setDataLoding(false);
          } else {
            if (data.project_name === "No Record Found") {
              SessionOut("internalServerError");
            } else if (data.project_name === "Invalid Request") {
              SessionOut("internalServerError");
            } else {
              alert("Contact Developer @ 8074725033");
              navigator("/dashboard");
            }
          }
        });
    };

    fetchProjectDetails();
    fetchProfile();
    fetchngTaxDetails();
  }, []);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (password1 === password2) {
      const passwordObject = {
        admin_email: adminEmail,
        admin_password: password2,
      };
      const options = {
        method: "PUT",
        headers: { "Content-Type": "application/json", user_header_key: token },
        body: JSON.stringify(passwordObject),
      };
      const endpoint = serviceURl + "account/password";

      await fetch(endpoint, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            alert(`${data.message}, Please Login Again!`);
            navigator("/login");
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              alert(data.message);
            }
          }
        });
    } else {
      alert("Password Mismatch, Try again!");
    }
  };

  const handleTaxRateChange = async (e) => {
    e.preventDefault();
    const taxObject = {
      tax_name: taxName,
      tax_percentage: taxRateIs,
    };

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json", user_header_key: token },
      body: JSON.stringify(taxObject),
    };
    const endpoint = serviceURl + `tax/update/${16073045}`;

    await fetch(endpoint, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert(`${data.message}`);
          window.location.reload();
        } else {
          if (data.message === "invalidToken") {
            SessionOut("invalidToken");
          } else if (data.message === "internalServerError") {
            SessionOut("internalServerError");
          } else {
            alert(data.message);
          }
        }
      });
  };

  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"settings"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Profile & Settings</h1>
            <hr />
          </div>
          {accessOnComponent ? (
            dataLoding ? (
              <LoaderShow />
            ) : (
              <>
                <>
                  {/* End Page Title */}
                  <section className="section profile">
                    <div className="row">
                      <div className="col-xl-4">
                        <div className="card">
                          <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                            <img
                              src="/images/profile.png"
                              alt="Profile"
                              className="rounded-circle"
                            />
                            <h2>{UserName}</h2>
                            <h3>
                              {userRole === "superAdmin"
                                ? "Super Admin"
                                : "Admin"}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-8 my-auto">
                        <div className="card">
                          <div className="card-body pt-3">
                            <b>Password Update :</b>
                            <form
                              className="mt-3"
                              onSubmit={handlePasswordChange}
                            >
                              <div class="form-group">
                                <input
                                  type="password"
                                  class="form-control"
                                  id="password1"
                                  placeholder="Enter New Password"
                                  required
                                  minlength="8"
                                  onChange={(e) => {
                                    setPassword1(e.target.value);
                                  }}
                                />
                              </div>
                              <div class="form-group">
                                <input
                                  type="password"
                                  class="form-control"
                                  id="password2"
                                  placeholder="Confirm New Password"
                                  required
                                  minlength="8"
                                  onChange={(e) => {
                                    setPassword2(e.target.value);
                                  }}
                                />
                              </div>
                              <button type="submit" class="btn btn-primary">
                                Update Password
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-lg-6">
                        <div className="card">
                          <div className="card-body pt-3">
                            <b>Tax Details Update : Tax name & Tax Rate</b>
                            <form
                              className="mt-3"
                              onSubmit={handleTaxRateChange}
                            >
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="texName"
                                  placeholder="Enter Tax Name"
                                  value={taxName}
                                  required
                                  onChange={(e) => {
                                    setTaxName(e.target.value);
                                  }}
                                />
                              </div>
                              <div class="form-group">
                                <input
                                  type="number"
                                  class="form-control"
                                  id="taxRate"
                                  placeholder="Tax Rate"
                                  value={taxRateIs}
                                  required
                                  onChange={(e) => {
                                    setTaxRateIs(e.target.value);
                                  }}
                                />
                              </div>
                              <div>
                                <small>
                                  Any Wrong Data!!! To get prevoius data reload
                                  the page!
                                </small>
                              </div>
                              <button
                                type="submit"
                                class="mt-2 btn btn-primary"
                              >
                                Update Tax Rate
                              </button>
                            </form>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="text-center">
                          <b className="text-info">
                            Any Issues, Contact Developer : Ganesh (8074725033)
                          </b>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6">
                        <div className="card">
                          <div className="card-body pt-3">
                            <b>Software Settings</b>
                            <br></br>
                            <table class="table mt-3">
                              <tbody>
                                <tr>
                                  <th scope="row">Next Due Date : </th>
                                  <td>{projectDetails.software_expire_on}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Days Left : </th>
                                  <td>
                                    <b className="text-info">
                                      {projectDetails.days_left}
                                    </b>{" "}
                                    <small>Days to Renew Software.</small>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Renew Amount : </th>
                                  <td>
                                    <b className="text-info">
                                      {formatAmountInr(projectDetails.amount)}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Bill Payment Cycle : </th>
                                  <td>Annualy (Every Year)</td>
                                </tr>
                                <tr>
                                  <th scope="row">Contact For Renew : </th>
                                  <td>
                                    {projectDetails.developer} (
                                    {projectDetails.developer_contact})
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              </>
            )
          ) : (
            <AccessDenide />
          )}
          {/* End Page Title */}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default AccountSettings;
