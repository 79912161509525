import React, { useEffect } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import AccessDenide from "../AccessDenide/AccessDenide";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Footer from "../Footer";
import {
  ServiceURL,
  authTokenHeader,
  todayDateString,
  formatAmountInr,
  formatNumberIn,
  SessionOut,
} from "../Utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EditStock = () => {
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);

  let params = useParams();
  const stockId = params.id;

  const [dataLoading, setDataLoading] = React.useState(false);

  const [stockName, setStockName] = React.useState("");
  const [stockStatus, setStockStatus] = React.useState("");
  const [stockQty, setStockQty] = React.useState("");
  const [stockAmount, setStockAmount] = React.useState("");

  const [stockAction, setStockAction] = React.useState("default");
  const [updatedStockQty, setUpdatedStockQty] = React.useState("");
  const [updatedStockAmount, setUpdatedStockAmount] = React.useState("");
  const [defaultStockQty, setDefaultStockQty] = React.useState("");
  const [defaultStockAmount, setDefaultStockAmount] = React.useState("");
  const [updatedStockAvlb, setUpdatedStockAvlb] = React.useState("");
  const [defaultStockAvlb, setDefaultStockAvlb] = React.useState("");

  const token = authTokenHeader();
  const serviceurl = ServiceURL();
  const navigator = useNavigate();

  const updateStock = async (e) => {
    e.preventDefault();
    const updateStockObjet = {
      stock_name: stockName,
      stock_status: stockStatus === "" ? 0 : stockStatus,
      stock_amount: updatedStockAmount,
      stock_qty: updatedStockQty,
      stock_available: updatedStockAvlb,
    };
    let options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        user_header_key: token,
      },
      body: JSON.stringify(updateStockObjet),
    };
    const endpoint = serviceurl + `stock/update/${stockId}`;
    await fetch(endpoint, options)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert(data.message);
          navigator("/list-stocks");
        } else {
          if (data.message === "invalidToken") {
            SessionOut("invalidToken");
          } else if (data.message === "internalServerError") {
            SessionOut("internalServerError");
          } else {
            toast.error(data.message);
          }
        }
      });
  };

  useEffect(() => {
    const getStockDetails = async () => {
      setDataLoading(true);
      const endpoint = serviceurl + `stock/${stockId}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          user_header_key: token,
        },
      };
      await fetch(endpoint, options)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setStockName(data.results[0].stock_name);
            setStockStatus(data.results[0].stock_status);
            setStockQty(data.results[0].stock_quantity);
            setUpdatedStockQty(data.results[0].stock_quantity);
            setDefaultStockQty(data.results[0].stock_quantity);
            setStockAmount(data.results[0].stock_price);
            setUpdatedStockAmount(data.results[0].stock_price);
            setDefaultStockAmount(data.results[0].stock_price);
            setDefaultStockAvlb(data.results[0].stock_available);
            setUpdatedStockAvlb(data.results[0].stock_available);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              alert(data.message);
              navigator("/list-expanses");
            }
          }
          setDataLoading(false);
        });
    };
    getStockDetails();
  }, []);

  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"allStocks"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Update Stock</h1>
            <hr />
          </div>
          {accessOnComponent ? (
            <section className="section dashboard">
              <>
                <div className="card mb-4">
                  <div className="card-body">
                    <h5 className="card-title">
                      Update Stock <span>| {todayDateString(new Date())}</span>
                    </h5>
                    <form onSubmit={updateStock}>
                      <div className="row mt-1">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Stock Name"
                              value={stockName}
                              onChange={(e) => setStockName(e.target.value)}
                              required
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <select
                            className="form-select"
                            onChange={(e) => setStockStatus(e.target.value)}
                            required
                            defaultValue={stockStatus}
                          >
                            <option value="">Select Stock Status</option>
                            <option
                              value="0"
                              selected={stockStatus === "0" ? "selected" : ""}
                            >
                              Use For Sale
                            </option>
                            <option
                              value="1"
                              selected={stockStatus === "1" ? "selected" : ""}
                            >
                              Dont Use For Sale
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <select
                              className="form-select"
                              defaultValue={"default"}
                              required
                              onChange={(e) => {
                                setStockAction(e.target.value);
                                if (
                                  e.target.value === "add" ||
                                  e.target.value === "remove"
                                ) {
                                  // do nothing
                                } else {
                                  setUpdatedStockAmount(defaultStockAmount);
                                  setUpdatedStockQty(defaultStockQty);
                                }
                              }}
                            >
                              <option value="default" selected>
                                Keep Default
                              </option>
                              <option value="add">Add New Stock</option>
                              <option value="remove">Remove From Stock</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control"
                              id="exampleInputEmail1"
                              min={0}
                              value={
                                stockAction === "default" ? stockAmount : null
                              }
                              readOnly={stockAction === "default"}
                              onChange={(e) => {
                                if (stockAction === "add") {
                                  setUpdatedStockAmount(
                                    Number(defaultStockAmount) +
                                      Number(e.target.value)
                                  );
                                } else {
                                  setUpdatedStockAmount(
                                    Number(defaultStockAmount) -
                                      Number(e.target.value)
                                  );
                                }
                              }}
                              placeholder="Enter Stock Amount (eg. 7500)"
                            />
                            <small>
                              Updated Stock Amount :{" "}
                              <b>{formatAmountInr(updatedStockAmount)}</b>
                            </small>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <input
                              type="number"
                              min={0}
                              className="form-control"
                              id="exampleInputEmail1"
                              value={
                                stockAction === "default" ? stockQty : null
                              }
                              step="any"
                              readOnly={stockAction === "default"}
                              placeholder="Enter Stock Quantity (eg. 35)"
                              onChange={(e) => {
                                if (stockAction === "add") {
                                  setUpdatedStockQty(
                                    Number(defaultStockQty) +
                                      Number(e.target.value)
                                  );
                                  setUpdatedStockAvlb(
                                    Number(defaultStockAvlb) +
                                      Number(e.target.value)
                                  );
                                } else {
                                  setUpdatedStockQty(
                                    Number(defaultStockQty) -
                                      Number(e.target.value)
                                  );
                                  setUpdatedStockAvlb(
                                    Number(defaultStockAvlb) -
                                      Number(e.target.value)
                                  );
                                }
                              }}
                            />
                            <small>
                              Total Stock Qty:{" "}
                              <b>{formatNumberIn(updatedStockQty)}</b> /
                              Avl.Stock :{" "}
                              <b>{formatNumberIn(updatedStockAvlb)}</b>
                            </small>
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="mt-1 btn baseBackGroundColor btn-block"
                      >
                        Update Now
                      </button>
                    </form>
                  </div>
                </div>
              </>
            </section>
          ) : (
            <AccessDenide />
          )}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default EditStock;
