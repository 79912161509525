import React, { useEffect } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import AccessDenide from "../AccessDenide/AccessDenide";
import LoaderShow from "../Loader/LoaderShow";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import {
  ServiceURL,
  authTokenHeader,
  formatAmountInr,
  formatDateDyMmYyyy,
  SessionOut,
} from "../Utils/utils";
import Footer from "../Footer";
import { toast } from "react-toastify";

const ListOfExpanses = () => {
  const navigate = useNavigate();
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);

  const [dataLoding, setDataLoading] = React.useState(false);

  const [allExpanses, setAllExpanses] = React.useState([]);
  const serviceURl = ServiceURL();
  const token = authTokenHeader();

  useEffect(() => {
    const fetchingExpanses = async () => {
      setDataLoading(true);
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/josn", user_header_key: token },
      };
      const endpoint = serviceURl + "expanses/list";
      await fetch(endpoint, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setAllExpanses(data.results);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              toast.error(data.message);
              setAllExpanses(data.results);
            }
          }
          setDataLoading(false);
        });
    };

    fetchingExpanses();
  }, []);

  $(document).ready(function () {
    setTimeout(function () {
      $("#listOfAllExpanses").DataTable();
    }, 1500);
  });

  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"allExpanses"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>List Of All Expanses</h1>
            <hr />
          </div>
          {accessOnComponent ? (
            <section className="section dashboard">
              <>
                <div className="card mb-4">
                  <div className="card-body">
                    <h5 className="card-title">All Expanses</h5>
                    {dataLoding ? (
                      <LoaderShow />
                    ) : (
                      <>
                        <div className="mt-2">
                          <div class="table-responsive">
                            <table
                              className="table datatable text-center"
                              id="listOfAllExpanses"
                            >
                              <thead>
                                <tr>
                                  <th className="text-center" scope="col">
                                    #
                                  </th>
                                  <th className="text-center" scope="col">
                                    ExpanseBy
                                  </th>
                                  <th className="text-center" scope="col">
                                    ExpanseType
                                  </th>
                                  <th className="text-center" scope="col">
                                    ExpanseReason
                                  </th>
                                  <th className="text-center" scope="col">
                                    ExpanseAmount
                                  </th>
                                  <th className="text-center" scope="col">
                                    CreatedDate
                                  </th>
                                  <th className="text-center" scope="col">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {!allExpanses ? (
                                  <>
                                    <div className="text-center mt-5">
                                      <b>No Expanses Found!</b>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {allExpanses.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <th scope="row">
                                            #{item.expanse_id}
                                          </th>
                                          <td>
                                            <Link
                                              to="#"
                                              title={item.expanse_by_name}
                                            >
                                              {item.expanse_by_mobile_no} -{" "}
                                              <small>
                                                {item.expanse_by_name}
                                              </small>
                                            </Link>
                                          </td>
                                          <td>
                                            {item.expanse_type === "C" ? (
                                              <b className="text-success">
                                                Credit
                                              </b>
                                            ) : (
                                              <b className="text-danger">
                                                Debit
                                              </b>
                                            )}
                                          </td>
                                          <td>{item.expanse_reason}</td>
                                          <td>
                                            {item.expanse_type === "C" ? (
                                              <b className="text-success">
                                                {" "}
                                                +{" "}
                                                {formatAmountInr(
                                                  item.expanse_amount
                                                )}
                                              </b>
                                            ) : (
                                              <b className="text-danger">
                                                {" "}
                                                -{" "}
                                                {formatAmountInr(
                                                  item.expanse_amount
                                                )}
                                              </b>
                                            )}
                                          </td>
                                          <td>
                                            {formatDateDyMmYyyy(
                                              new Date(`${item.created}`)
                                            )}
                                          </td>
                                          <td>
                                            <Link
                                              style={{ marginRight: "12px" }}
                                              to={`/update-expanse/${item.expanse_id}`}
                                            >
                                              <i className="bi bi-pencil mr-1" />{" "}
                                            </Link>
                                            {userRole === "superAdmin" ? (
                                              <Link
                                                className="ml-1"
                                                to={`/delete-expanse/${item.expanse_id}`}
                                              >
                                                <i className="bi bi-trash" />
                                              </Link>
                                            ) : null}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            </section>
          ) : (
            <AccessDenide />
          )}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default ListOfExpanses;
