import React, { useEffect } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import AccessDenide from "../AccessDenide/AccessDenide";
import LoaderShow from "../Loader/LoaderShow";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import {
  ServiceURL,
  authTokenHeader,
  formatDateDyMmYyyy,
  SessionOut,
} from "../Utils/utils";
import { toast } from "react-toastify";

const ListOfAllCustomers = () => {
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);

  const [dataLoding, setDataLoading] = React.useState(false);

  const [allCustomers, setAllCustomers] = React.useState([]);

  const token = authTokenHeader();
  const ServiceCall = ServiceURL();

  useEffect(() => {
    const fetchingCustomersList = async () => {
      setDataLoading(true);
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json", user_header_key: token },
      };
      const endpoint = ServiceCall + "account/customers";

      await fetch(endpoint, options)
        .then((resposne) => resposne.json())
        .then((data) => {
          if (data.success) {
            setAllCustomers(data.results);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              toast.error(data.message);
              setAllCustomers(data.results);
            }
          }
          setDataLoading(false);
        });
    };
    fetchingCustomersList();
  }, []);

  $(document).ready(function () {
    setTimeout(function () {
      $("#listOfAllCustomers").DataTable();
    }, 1500);
  });

  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"allCustomers"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>All Customer</h1>
            <hr />
          </div>
          {accessOnComponent ? (
            <section className="section dashboard">
              <>
                <div className="card mb-4">
                  <div className="card-body">
                    <h5 className="card-title">All Customers</h5>
                    {dataLoding ? (
                      <LoaderShow />
                    ) : (
                      <>
                        <div className="mt-2">
                          <div class="table-responsive">
                            <table
                              className="table  datatable text-center"
                              id="listOfAllCustomers"
                            >
                              <thead>
                                <tr>
                                  <th className="text-center" scope="col">
                                    CustomerId#
                                  </th>
                                  <th className="text-center" scope="col">
                                    CustomerName
                                  </th>
                                  <th className="text-center" scope="col">
                                    CustomerMobile
                                  </th>
                                  <th className="text-center" scope="col">
                                    Address
                                  </th>
                                  <th className="text-center" scope="col">
                                    CreatedDate
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {!allCustomers ? (
                                  <>
                                    <div className="text-center mt-5">
                                      <b>No Customer Data Found!</b>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {allCustomers.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <th scope="row">
                                            <Link to="#">#{item.user_id}</Link>
                                          </th>
                                          <td>{item.user_name}</td>
                                          <td>
                                            <b className="baseColor">
                                              {item.user_mobile}
                                            </b>
                                          </td>
                                          <td>{item.user_address}</td>
                                          <td>
                                            {formatDateDyMmYyyy(
                                              new Date(`${item.created}`)
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            </section>
          ) : (
            <AccessDenide />
          )}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default ListOfAllCustomers;
