import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../Header";
import SideBar from "../SideBar";
import AccessDenide from "../AccessDenide/AccessDenide";
import LoaderShow from "../Loader/LoaderShow";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Footer from "../Footer";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import {
  formatAmountInr,
  formatNumberIn,
  authTokenHeader,
  ServiceURL,
  getFlooredFixed,
  formatNumberNoDacimal,
  formatDateDyMmYyyy,
  SessionOut,
} from "../Utils/utils";

const OrderDetailsView = () => {
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);
  const [dataLoding, setDataLoading] = React.useState(false);
  const [orderDetails, setOrderDetails] = React.useState([]);
  let params = useParams();
  const orderId = params.id;

  const token = authTokenHeader();
  const serviceURl = ServiceURL();

  useEffect(() => {
    const getOrderDetails = async () => {
      setDataLoading(true);
      const endpoint = serviceURl + `sales/${orderId}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          user_header_key: token,
        },
      };
      await fetch(endpoint, options)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setOrderDetails(data.results);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              alert(data.message);
            }
          }
          setDataLoading(false);
        });
    };
    getOrderDetails();
  }, []);

  const returnView = orderDetails.map((item, index) => {
    const totalWithExtraAmount = item.total_price + item.extra_product_amount;

    const taxableAmount = totalWithExtraAmount - item.discount_amount;

    const afterTaxTotal = taxableAmount + item.tax_total;

    const afterExchangeAmountTotal =
      afterTaxTotal - (item.exchangeDetails[0]?.ex_amount || 0);

    return (
      <>
        <div className="card" key={index}>
          <div className="card-body mainContent">
            <div class="watermark__inner">
              <div class="watermark__body text-center">
                Seetharam Jewellers
                <br />
                Armoor
              </div>
            </div>
            <div className="container mb-5 mt-3">
              <div className="container my-5">
                <div className="row">
                  <div className="col-lg-8 col-md-8">
                    <ul className="list-unstyled">
                      <li className="text-muted">
                        To:{" "}
                        <span style={{ color: "#5d9fc5" }}>
                          {item.customerDetails[0].user_name}
                        </span>
                      </li>
                      <li className="text-muted">
                        Address: {item.customerDetails[0].user_address}
                      </li>
                      <li className="text-muted">
                        Email: {item.customerDetails[0].user_email}
                      </li>
                      <li className="text-muted">
                        <i className="fas fa-phone" />
                        Mobile: {item.customerDetails[0].user_mobile}
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <ul className="list-unstyled">
                      <li className="text-muted">
                        <i
                          className="fas fa-circle"
                          style={{ color: "#84B0CA" }}
                        />{" "}
                        <span className="fw-bold">ID:</span>#{orderId}
                      </li>
                      <li className="text-muted">
                        <i
                          className="fas fa-circle"
                          style={{ color: "#84B0CA" }}
                        />{" "}
                        <span className="fw-bold">Creation Date: </span>
                        {formatDateDyMmYyyy(new Date(`${item.created}`))}
                      </li>
                      <li className="text-muted">
                        <i
                          className="fas fa-circle"
                          style={{ color: "#84B0CA" }}
                        />{" "}
                        <span className="me-1 fw-bold">Status:</span>
                        {item.sale_status === 1 ? (
                          <span className="badge bg-success text-white fw-bold">
                            Paid
                          </span>
                        ) : (
                          <span className="badge bg-danger text-white fw-bold">
                            Due
                          </span>
                        )}
                      </li>
                      <li className="text-muted">
                        <i
                          className="fas fa-circle"
                          style={{ color: "#84B0CA" }}
                        />{" "}
                        <span className="me-1 fw-bold">Payment Method:</span>
                        {item.payment_method === "C" ? (
                          <b>CASH</b>
                        ) : item.payment_method === "O" ? (
                          <b>ONLINE</b>
                        ) : item.payment_method === "ND" ? (
                          <b>NO DECISION</b>
                        ) : (
                          <b>UPI</b>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <b>Customer ID</b> : #{item.customerDetails[0].user_id}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <b>Invoice Dated Gram Price</b> :{" "}
                    {formatAmountInr(item.productDetails[0].one_gm_price)}
                  </div>
                </div>
                <hr />
                <b className="mt-4 text-muted">Product Details : </b>
                <div className="row my-2 mt-2 mx-1 justify-content-center">
                  <div className="col-lg-12 col-md-12">
                    <div class="table-responsive">
                      {orderDetails[0]?.order_type === "W" ? (
                        <>
                          <table className="table text-center">
                            <tr>
                              <th>Product Name</th>
                              <th>No Of Grams</th>
                              <th>Wastage Grams</th>
                              <th>Melting @</th>
                              <th>Purity @</th>
                              <th>Making. Amt</th>
                              <th>Item. Status</th>
                              <th>Total. Amt</th>
                            </tr>
                            {item.productDetails.map((productItem, index) => (
                              <tr key={index}>
                                <td>{productItem.product_name}</td>
                                <td>
                                  {getFlooredFixed(productItem.no_of_gms, 3)}
                                </td>
                                <td>
                                  {getFlooredFixed(productItem.wastage_gms, 3)}
                                </td>
                                <td>
                                  {productItem.melting_at > 0
                                    ? `${Number(productItem.melting_at)}%`
                                    : "--"}
                                </td>
                                <td>
                                  {productItem.purity_at > 0
                                    ? getFlooredFixed(productItem.purity_at, 3)
                                    : "--"}
                                </td>
                                <td>
                                  {formatAmountInr(productItem.making_amount)}
                                </td>
                                <td>
                                  {productItem.item_status === "0"
                                    ? "PAID"
                                    : "DUE"}
                                </td>
                                <td>
                                  <b>
                                    {formatNumberNoDacimal(
                                      productItem.total_amount
                                    )}
                                  </b>
                                </td>
                              </tr>
                            ))}

                            {item.extra_product_amount > 0 ? (
                              <>
                                <tr>
                                  <td colSpan={7}>
                                    Other : {item.extra_product_desc}
                                  </td>
                                  <td>
                                    <b>
                                      {formatAmountInr(
                                        item.extra_product_amount
                                      )}
                                    </b>
                                  </td>
                                </tr>
                              </>
                            ) : null}
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>
                                <b>Total : </b>
                              </td>
                              <td>
                                <b>{formatAmountInr(totalWithExtraAmount)}</b>
                              </td>
                            </tr>
                          </table>
                        </>
                      ) : (
                        <>
                          <table className="table text-center">
                            <tr>
                              <th>Desc.</th>
                              <th>Gram.Price</th>
                              <th>Gross WT</th>
                              <th>Net WT</th>
                              <th>Amount</th>
                              <th>VA % (Amt)</th>
                              <th>Stones</th>
                              <th>Item. Status</th>
                              <th>Total. Amt</th>
                            </tr>
                            {item.productDetails.map((productItem, index) => (
                              <tr key={index}>
                                <td>{productItem.product_name}</td>
                                <td>{productItem.one_gm_price}</td>
                                <td>
                                  {getFlooredFixed(
                                    productItem.retail_gross_weight,
                                    3
                                  )}
                                </td>
                                <td>
                                  {getFlooredFixed(
                                    productItem.retail_net_weight,
                                    3
                                  )}
                                </td>
                                <td>
                                  {formatNumberNoDacimal(
                                    productItem.retail_amount
                                  )}
                                </td>
                                <td>
                                  {productItem.retail_value_added} %{" "}
                                  <small>
                                    ({productItem.retail_value_added_amount})
                                  </small>
                                </td>
                                <td>{productItem.stones_amount}</td>
                                <td>
                                  {productItem.item_status === "0"
                                    ? "PAID"
                                    : "DUE"}
                                </td>
                                <td>
                                  <b>
                                    {formatNumberNoDacimal(
                                      productItem.total_amount
                                    )}
                                  </b>
                                </td>
                              </tr>
                            ))}

                            {item.extra_product_amount > 0 ? (
                              <>
                                <tr>
                                  <td colSpan={8}>
                                    Other : {item.extra_product_desc}
                                  </td>
                                  <td>
                                    <b>
                                      {formatAmountInr(
                                        item.extra_product_amount
                                      )}
                                    </b>
                                  </td>
                                </tr>
                              </>
                            ) : null}
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>
                                <b>Total : </b>
                              </td>
                              <td>
                                <b>{formatAmountInr(totalWithExtraAmount)}</b>
                              </td>
                            </tr>
                          </table>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <hr />
                {item.exchangeDetails[0]?.ex_amount || 0 > 0 ? (
                  <>
                    <b className="mt-2 text-muted">Exchange Details : </b>
                    <div className="row my-2 mx-1 justify-content-center">
                      <div className="col-lg-12 col-md-12">
                        <div class="table-responsive">
                          <table className="mt-1 table text-center">
                            <tr>
                              <th>Product Name</th>
                              <th>Rate On Gram</th>
                              <th>
                                {orderDetails[0].order_type === "W"
                                  ? "Grams.WT"
                                  : "Gross.WT"}
                              </th>
                              <th>Net.WT</th>
                              <th>Melting @</th>
                              <th>Purity @</th>

                              <th>Total. Amt</th>
                            </tr>
                            <tr>
                              <td>
                                {item.exchangeDetails[0]?.ex_product_name}
                              </td>
                              <td>
                                {formatAmountInr(
                                  item.exchangeDetails[0]?.ex_one_gm_rate
                                )}
                              </td>
                              <td>
                                {getFlooredFixed(
                                  orderDetails[0].order_type === "W"
                                    ? item.exchangeDetails[0]?.ex_gms_wt
                                    : item.exchangeDetails[0]?.gross_weight,
                                  3
                                )}
                              </td>
                              <td>
                                {formatNumberIn(
                                  item.exchangeDetails[0]?.ex_netwt
                                )}
                              </td>
                              <td>
                                {`${Number(item.exchangeDetails[0]?.melting)}%`}
                              </td>
                              <td>
                                {getFlooredFixed(
                                  item.exchangeDetails[0]?.ex_purity,
                                  3
                                )}
                              </td>
                              <td>
                                {formatNumberNoDacimal(
                                  item.exchangeDetails[0]?.ex_amount
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>
                                <b>Total : </b>
                              </td>
                              <td>
                                <b>
                                  {formatAmountInr(
                                    item.exchangeDetails[0].ex_amount
                                  )}
                                </b>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                ) : (
                  <></>
                )}

                <div className="row">
                  <div className="col-md-6">
                    <ul className="list-unstyled">
                      <li className="text-muted ms-3 mt-2">
                        <span className="text-black me-4">
                          <b>PAID Amount</b>
                        </span>
                        <span className="text-success" style={{ fontSize: 20 }}>
                          <b>{formatAmountInr(item.paid_amount)}</b>
                        </span>
                      </li>
                      <li className="text-muted ms-3 mt-2">
                        <span className="text-black me-4">
                          <b>DUE Amount</b>
                        </span>
                        <span className="text-danger" style={{ fontSize: 20 }}>
                          <b>{formatAmountInr(item.due_amount)}</b>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 text-right pr-5">
                    <table className="table table-borderless table-sm">
                      <tr>
                        <th>
                          <span className="text-black me-4">TOTAL AMOUNT</span>
                        </th>
                        <td>{formatAmountInr(totalWithExtraAmount)}</td>
                      </tr>
                      <tr>
                        <th>
                          <span className="text-black me-4">Less:Discount</span>
                        </th>
                        <td>- {formatAmountInr(item.discount_amount)}</td>
                      </tr>
                      <tr>
                        <th>
                          <span className="text-black me-4">
                            <b>Taxable Amount</b>
                          </span>
                        </th>
                        <td>{formatAmountInr(taxableAmount)}</td>
                      </tr>
                      <tr>
                        <th>
                          <span className="text-black me-4">
                            Tax Total @ {item.tax_at}%
                          </span>
                        </th>
                        <td>{formatAmountInr(item.tax_total)}</td>
                      </tr>
                      <tr>
                        <th>
                          <span className="text-black me-4">
                            <b>After Tax</b>
                          </span>
                        </th>
                        <td>
                          <b>{formatAmountInr(afterTaxTotal)}</b>
                        </td>
                      </tr>
                      {item.exchangeDetails[0]?.ex_amount > 0 ? (
                        <tr>
                          <th>
                            <span className="text-black me-4">
                              Less:O/E Value
                            </span>
                          </th>
                          <td>
                            -{" "}
                            {formatAmountInr(
                              item.exchangeDetails[0]?.ex_amount
                            )}
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}
                    </table>
                    <p className="text-black text-right">
                      <span className="text-black me-3">
                        {" "}
                        <b>GRAND TOTAL</b>
                      </span>
                      <span className="text-info" style={{ fontSize: 25 }}>
                        <b>
                          ₹{" "}
                          {formatNumberNoDacimal(
                            getFlooredFixed(afterExchangeAmountTotal, 0)
                          )}{" "}
                        </b>
                      </span>
                    </p>
                  </div>
                </div>
                <hr></hr>
                <div className="text-center">
                  <small className="text-center">
                    This Invoice is For View Perpose Only...
                  </small>
                  <div className="row mt-3 justify-content-center">
                    <div className="col-4 mb-2">
                      <Link
                        to={
                          orderDetails[0]?.order_type === "W"
                            ? `/view-invoice/${orderId}`
                            : `/invoice-order-view/${orderId}`
                        }
                        className="btn btn-sm baseBackGroundColor"
                      >
                        <i class="bi bi-file-earmark-pdf"></i> Open / Print
                        Order Invoice
                      </Link>
                    </div>
                    <div className="col-4 mb-2">
                      <Link
                        to={
                          orderDetails[0]?.order_type === "W"
                            ? `/update-order/${orderId}`
                            : `/update-order-retail/${orderId}`
                        }
                        className="btn btn-sm btn-info"
                      >
                        <i className="bi bi-pencil" /> Edit / Update Order
                      </Link>
                    </div>
                    {userRole === "superAdmin" ? (
                      <div className="col-4 mb-2">
                        <Link
                          to={`/delete-order/${orderId}`}
                          className="btn btn-sm btn-danger"
                        >
                          <i className="bi bi-trash" /> Delete Order
                        </Link>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  });

  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"dashboard"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>
              Order Details : #{orderId} (
              {orderDetails[0]?.order_type === "W" ? "Whole Sale" : "Retail"})
            </h1>
            <hr />
          </div>
          {accessOnComponent ? (
            dataLoding ? (
              <LoaderShow />
            ) : (
              <>{returnView}</>
            )
          ) : (
            <AccessDenide />
          )}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default OrderDetailsView;
