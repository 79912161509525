import React, { useEffect } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import AccessDenide from "../AccessDenide/AccessDenide";
import LoaderShow from "../Loader/LoaderShow";
import { Link } from "react-router-dom";
import {
  ServiceURL,
  authTokenHeader,
  formatAmountInr,
  SessionOut,
} from "../Utils/utils";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Footer from "../Footer";

const ListOfAllInvoices = () => {
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);

  const [dataLoding, setDataLoading] = React.useState(false);

  const [saleInvoices, setSaleInvoices] = React.useState([]);
  const serviceURl = ServiceURL();
  const token = authTokenHeader();

  useEffect(() => {
    const fetchingExpanses = async () => {
      setDataLoading(true);
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/josn", user_header_key: token },
      };
      const endpoint = serviceURl + "sales/by/paid";
      await fetch(endpoint, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setSaleInvoices(data.results);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              alert(data.message);
              setSaleInvoices(data.results);
            }
          }
          setDataLoading(false);
        });
    };

    fetchingExpanses();
  }, []);

  $(document).ready(function () {
    setTimeout(function () {
      $("#listOfInvoices").DataTable();
    }, 1500);
  });

  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"invoices"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Lit Of All Invoices</h1>
            <hr />
          </div>
          {accessOnComponent ? (
            <>
              <section className="section dashboard">
                <div className="row">
                  {/* Left side columns */}
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-12">
                        <div className="card recent-sales overflow-auto">
                          <div className="card-body">
                            <h5 className="card-title">
                              All Invoices <span>| Total</span>
                            </h5>
                            {dataLoding ? (
                              <LoaderShow />
                            ) : (
                              <>
                                <div class="table-responsive">
                                  <table
                                    className="table  datatable text-center"
                                    id="listOfInvoices"
                                  >
                                    <thead>
                                      <tr>
                                        <th className="text-center" scope="col">
                                          #
                                        </th>
                                        <th className="text-center" scope="col">
                                          CustomerMobile
                                        </th>
                                        <th className="text-center" scope="col">
                                          CustomerName
                                        </th>
                                        <th className="text-center" scope="col">
                                          OrderProducts
                                        </th>
                                        <th className="text-center" scope="col">
                                          TotalAmount
                                        </th>
                                        <th className="text-center" scope="col">
                                          PaidAmount
                                        </th>
                                        <th className="text-center" scope="col">
                                          CreatedDate
                                        </th>
                                        <th className="text-center" scope="col">
                                          Invoice/Bill
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {!saleInvoices ? (
                                        <b>No Data</b>
                                      ) : (
                                        <>
                                          {saleInvoices.map((item, index) => {
                                            return (
                                              <tr key={index}>
                                                <th scope="row">
                                                  <Link
                                                    to={`/view-order/${item.sale_id}`}
                                                  >
                                                    #{item.sale_id} (
                                                    {item.order_type})
                                                  </Link>
                                                </th>
                                                <td>{item.user_mobile}</td>
                                                <td>
                                                  {
                                                    item.customerDetails[0]
                                                      .user_name
                                                  }
                                                </td>
                                                <td>
                                                  <Link
                                                    to={`/view-order/${item.sale_id}`}
                                                  >
                                                    <b
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      title="Open Order to View Products."
                                                      style={{
                                                        cursor: "pointer",
                                                        backgroundColor:
                                                          "#f1f1f1",
                                                        padding: "5px",
                                                        borderRadius: "5px",
                                                        color: "#000",
                                                      }}
                                                    >
                                                      {
                                                        item.productDetails
                                                          .length
                                                      }
                                                    </b>
                                                  </Link>
                                                </td>
                                                <td>
                                                  <b className="baseColor">
                                                    {formatAmountInr(
                                                      item.grand_total
                                                    )}
                                                  </b>
                                                </td>
                                                <td>
                                                  <b className="text-success">
                                                    {formatAmountInr(
                                                      item.paid_amount
                                                    )}
                                                  </b>
                                                </td>
                                                <td>{item.created}</td>
                                                <td>
                                                  <Link
                                                    target="_blank"
                                                    to={
                                                      item.order_type === "W"
                                                        ? `/view-invoice/${item.sale_id}`
                                                        : `/invoice-order-view/${item.sale_id}`
                                                    }
                                                  >
                                                    <i class="bi bi-file-earmark-pdf"></i>{" "}
                                                    Invoice
                                                  </Link>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* End Recent Sales */}
                    </div>
                  </div>
                  {/* End Left side columns */}
                </div>
              </section>
            </>
          ) : (
            <AccessDenide />
          )}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default ListOfAllInvoices;
