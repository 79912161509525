import React from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import AccessDenide from "../AccessDenide/AccessDenide";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Footer from "../Footer";
import {
  ServiceURL,
  authTokenHeader,
  todayDateString,
  SessionOut,
} from "../Utils/utils";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddNewExpanse = () => {
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);

  const [expanseByName, setExpanseByName] = React.useState("");
  const [expanseByNumber, setExpanseByNumber] = React.useState("");
  const [expanseType, setExpanseType] = React.useState("");
  const [expanseAmount, setExpanseAmount] = React.useState("");
  const [expanseReason, setExpanseReason] = React.useState("");

  const token = authTokenHeader();
  const serviceurl = ServiceURL();
  const navigator = useNavigate();

  const addNewExpanse = async (e) => {
    e.preventDefault();
    const addExanseObject = {
      expanse_name: expanseByName,
      expanse_mobile: expanseByNumber,
      expanse_type: expanseType,
      expanse_amount: expanseAmount,
      expanse_reason: expanseReason,
    };
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        user_header_key: token,
      },
      body: JSON.stringify(addExanseObject),
    };
    const endpoint = serviceurl + "expanses/create";
    await fetch(endpoint, options)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert(data.message);
          navigator("/list-expanses");
        } else {
          if (data.message === "invalidToken") {
            SessionOut("invalidToken");
          } else if (data.message === "internalServerError") {
            SessionOut("internalServerError");
          } else {
            toast.error(data.message);
          }
        }
      });
  };

  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"addExpanse"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Add Expanse</h1>
            <hr />
          </div>
          {accessOnComponent ? (
            <section className="section dashboard">
              <>
                <div className="card mb-4">
                  <div className="card-body">
                    <h5 className="card-title">
                      Add New Expanse{" "}
                      <span>| {todayDateString(new Date())}</span>
                    </h5>
                    <form onSubmit={addNewExpanse}>
                      <div className="row mt-1">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Name By"
                              onChange={(e) => setExpanseByName(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Mobile number By"
                              onChange={(e) =>
                                setExpanseByNumber(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Amount"
                              onChange={(e) => setExpanseAmount(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <select
                            className="form-select"
                            onChange={(e) => setExpanseType(e.target.value)}
                            required
                          >
                            <option selected>Select Expanse Type</option>
                            <option value="D">Debit (-)</option>
                            <option value="C">Credit (+)</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              rows="2"
                              placeholder="Enter Reason For Expanse"
                              onChange={(e) => setExpanseReason(e.target.value)}
                              required
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="mt-1 btn baseBackGroundColor btn-block"
                      >
                        Add New
                      </button>
                    </form>
                  </div>
                </div>
              </>
            </section>
          ) : (
            <AccessDenide />
          )}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default AddNewExpanse;
