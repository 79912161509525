import React, { useEffect } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import AccessDenide from "../AccessDenide/AccessDenide";
import LoaderShow from "../Loader/LoaderShow";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import {
  ServiceURL,
  authTokenHeader,
  formatAmountInr,
  SessionOut,
} from "../Utils/utils";

const UpgradeVersion = () => {
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);
  const [dataLoding, setDataLoading] = React.useState(false);

  const [listUpdates, setListUpdates] = React.useState([]);

  const token = authTokenHeader();
  const serviceURl = ServiceURL();

  useEffect(() => {
    const fetchingUpgrades = async () => {
      setDataLoading(true);
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/josn", user_header_key: token },
      };
      const endpoint = serviceURl + "account/upgrades";
      await fetch(endpoint, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setListUpdates(data.upgrades);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              alert(data.message);
              window.location.href = "/dashboard";
            }
          }
          setDataLoading(false);
        });
    };

    fetchingUpgrades();
  }, []);

  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"upgrade"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Upgrade Version [Current : 2024.11]</h1>
            <hr />
          </div>
          {accessOnComponent ? (
            dataLoding ? (
              <LoaderShow />
            ) : (
              <>
                <>
                  {/* End Page Title */}
                  <section className="section profile">
                    <div className="row">
                      <div className="col-xl-12 my-auto">
                        <div className="card">
                          <div className="card-body pt-3">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Future</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col">Request</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!listUpdates ? (
                                  <b>No Data</b>
                                ) : (
                                  <>
                                    {listUpdates.map((update, index) => (
                                      <tr key={index}>
                                        <td>{update.update_msg}</td>
                                        <td>{formatAmountInr(update.amt)}</td>
                                        <td>
                                          <Link
                                            className="btn btn-sm btn-primary"
                                            to={`https://wa.me/918074725033?text=Required:${update.id}`}
                                          >
                                            Get This...
                                          </Link>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </tbody>
                            </table>
                            <hr></hr>
                            <div className="text-center">
                              <b>Developer Note :</b>
                              <br></br>
                              Above Amount is Fixed, There is no negotiable
                              option, and every future required min 3-5 days to
                              develop and deploy. future adding will start once
                              payment confirmed.<br></br>
                              <br></br>
                              Thankyou :-)<br></br>
                              B.Ganesh (+91 8074725033)
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              </>
            )
          ) : (
            <AccessDenide />
          )}
          {/* End Page Title */}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default UpgradeVersion;
