import React, { useEffect } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import {
  ServiceURL,
  authTokenHeader,
  formatAmountInr,
  formatDateDyMmYyyy,
  SessionOut,
} from "../Utils/utils";
import { Link } from "react-router-dom";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import AccessDenide from "../AccessDenide/AccessDenide";
import LoaderShow from "../Loader/LoaderShow";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Footer from "../Footer";
import { toast } from "react-toastify";

const SearchSaleCustomer = () => {
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);

  const [dataLoding, setDataLoading] = React.useState(false);

  const [searchSaleValue, setSearchSalevalue] = React.useState([]);
  const [searchSale, setSearchSale] = React.useState([]);

  const [listOfCustomers, setListOfCustomers] = React.useState([]);

  const [usersDataList, setUsersDataList] = React.useState([]);

  const token = authTokenHeader();
  const serviceurl = ServiceURL();

  useEffect(() => {
    $("#listOfSearchSaleCustomer").DataTable({
      retrieve: true,
      paging: false,
      searching: false,
    });
    const getCustomerList = async () => {
      setDataLoading(true);
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json", user_header_key: token },
      };
      const endpoint = serviceurl + "account/customers";
      await fetch(endpoint, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setListOfCustomers(data.results);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              toast.error(data.message);
            }
          }
          setDataLoading(false);
        });
    };
    getCustomerList();
  }, []);

  const searchSaleFetching = async (e) => {
    e.preventDefault();
    setDataLoading(true);
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json", user_header_key: token },
      body: JSON.stringify({
        search_input: searchSaleValue,
      }),
    };
    const endpoint = serviceurl + "sales/search";
    await fetch(endpoint, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSearchSale(data.results);
        } else {
          if (data.message === "invalidToken") {
            SessionOut("invalidToken");
          } else if (data.message === "internalServerError") {
            SessionOut("internalServerError");
          } else {
            toast.error(data.message);
            setSearchSale(data.results);
          }
        }
        setDataLoading(false);
      });
  };

  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"searchSaleCustomer"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Search Sale / Customer</h1>
            <hr />
          </div>
          {accessOnComponent ? (
            <section className="section dashboard">
              <>
                <div className="card p-4 mb-4">
                  <form>
                    <div className="row">
                      <div className="col-md-8 mt-2">
                        <div class="form-group">
                          <input
                            type="text"
                            list="userNameSuggestion"
                            class="form-control"
                            id="searchOrderDetails"
                            placeholder="Enter Order Id / Mobile Number / Customer Name"
                            onChange={(e) => {
                              setSearchSalevalue(e.target.value);
                              let userSyncList = listOfCustomers.filter(
                                (x) =>
                                  x.user_name
                                    .toLowerCase()
                                    .includes(e.target.value.toLowerCase()) ||
                                  x.user_mobile.includes(e.target.value)
                              );
                              setUsersDataList(userSyncList);
                            }}
                          />
                          <datalist id="userNameSuggestion">
                            {usersDataList.map((user, i) => (
                              <option key={i} value={user.user_mobile}>
                                {user.user_name}
                              </option>
                            ))}
                          </datalist>
                        </div>
                      </div>
                      <div className="col-md-4 mt-2">
                        <button
                          type="submit"
                          onClick={searchSaleFetching}
                          class="btn btn-primary btn-block baseBackGroundColor"
                        >
                          Get Details
                        </button>
                      </div>
                    </div>
                  </form>
                  {dataLoding ? (
                    <LoaderShow />
                  ) : (
                    <>
                      <div className="mt-4">
                        <div class="table-responsive">
                          <table
                            className="table  datatable text-center"
                            id="listOfSearchSaleCustomer"
                          >
                            <thead>
                              <tr>
                                <th className="text-center" scope="col">
                                  #
                                </th>
                                <th className="text-center" scope="col">
                                  CustomerName
                                </th>
                                <th className="text-center" scope="col">
                                  CustomerMobile
                                </th>
                                <th className="text-center" scope="col">
                                  OrderProducts
                                </th>
                                <th className="text-center" scope="col">
                                  TotalAmount
                                </th>
                                <th className="text-center" scope="col">
                                  CreatedDate
                                </th>
                                <th className="text-center" scope="col">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {!searchSale ? (
                                <>
                                  <div className="text-center mt-5">
                                    <b>No Data Found!</b>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {searchSale.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <th scope="row">
                                          <Link
                                            to={`/view-order/${item.sale_id}`}
                                          >
                                            #{item.sale_id} ({item.order_type})
                                          </Link>
                                        </th>
                                        <td>
                                          {item.customerDetails[0].user_name}
                                        </td>
                                        <td>{item.user_mobile}</td>
                                        <td>
                                          <Link
                                            to={`/view-order/${item.sale_id}`}
                                          >
                                            <b
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title="Open Order to View Products."
                                              style={{
                                                cursor: "pointer",
                                                backgroundColor: "#f1f1f1",
                                                padding: "5px",
                                                borderRadius: "5px",
                                                color: "#000",
                                              }}
                                            >
                                              {item.productDetails.length}
                                            </b>
                                          </Link>
                                        </td>
                                        <td>
                                          <b className="baseColor">
                                            {formatAmountInr(item.grand_total)}
                                          </b>
                                        </td>
                                        <td>
                                          {formatDateDyMmYyyy(
                                            new Date(`${item.created}`)
                                          )}
                                        </td>
                                        <td>
                                          <Link
                                            style={{ marginRight: "12px" }}
                                            to={
                                              item.order_type === "W"
                                                ? `/update-order/${item.sale_id}`
                                                : `/update-order-retail/${item.sale_id}`
                                            }
                                          >
                                            <i className="bi bi-pencil mr-1" />{" "}
                                          </Link>
                                          {userRole === "superAdmin" ? (
                                            <Link
                                              className="ml-1"
                                              to={`/delete-order/${item.sale_id}`}
                                            >
                                              <i className="bi bi-trash" />
                                            </Link>
                                          ) : null}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            </section>
          ) : (
            <AccessDenide />
          )}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default SearchSaleCustomer;
