import Logout from "../Account/Logout";

export const ServiceURL = () => {
  // For Local Server URL
  // return "http://localhost:5000/api/v1/";

  // For Production Server URL
  return "https://dev.seetharamjewellers.com/api/v1/";
};

export const projectDetailsUrl = () => {
  return "https://ganeshbondla.in/projects/fetchDetails?project_key=9ee401f083326fbac2dbdf054df796b2";
};

export const authTokenHeader = () => {
  const token = localStorage.getItem("SRtoken");
  return token;
};

export const isLogin = async (token) => {
  const UrlIs = ServiceURL();
  const endpoint = UrlIs + "account/auth";

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      user_header_key: token,
    },
  };
  const results = await fetch(endpoint, options);
  const response = await results.json();
  return response;
};

export const formatAmountInr = (amount) => {
  const returnAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "INR",
  }).format(amount);
  return returnAmount;
};

export const getFlooredFixed = (v, d) => {
  return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
};

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export const formatDateDyMmYyyy = (date) => {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join("/");
};

export const formatNumberNoDacimal = (number) => {
  let fNumber = new Intl.NumberFormat("en-US");
  return fNumber.format(number);
};

export const formatNumberIn = (number) => {
  return Number(number)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const todayDateString = (date) => {
  const dateIs = new Date(date).toDateString();
  return dateIs;
};

export const selectRandomObjectValue = (lisOfData) => {
  const listLen = lisOfData.length;
  return lisOfData[Math.floor(Math.random() * listLen)];
};

export const SessionOut = (errMsg) => {
  let passMsg;
  if ((errMsg = "invalidToken")) {
    passMsg = "Lost Session!, Login Again!";
  } else {
    passMsg = "Internal Server Error!, Login Again!";
  }
  return <Logout errMsg={passMsg} />;
};
