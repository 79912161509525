import React from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import { RoleContext } from "../PrivateRoute/PrivateRoute";

const AccessDenide = () => {
  const userRole = React.useContext(RoleContext);
  console.log(userRole);
  return (
    <>
      <Header />
      <SideBar activeNav={""} />
      <>
        <main id="main" className="main">
          <div className="mb-5">
            <h4 className="mb-4">
              <b>Access Denied!</b>
            </h4>
            <b className="mt-5 baseColor">
              You Don't Have Access To This Page!
            </b>
            <br />
            <br />
            <b className="mt-5 baseColor">Back To Dashboard</b>
            <br />
            <br />
            <b className="mt-5 baseColor">Thankyou.</b>
          </div>
          {/* End Page Title */}
        </main>
        {/* End #main */}
      </>
    </>
  );
};

export default AccessDenide;
