import React, { useEffect } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import AccessDenide from "../AccessDenide/AccessDenide";
import LoaderShow from "../Loader/LoaderShow";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import {
  ServiceURL,
  authTokenHeader,
  formatAmountInr,
  formatNumberIn,
  formatDateDyMmYyyy,
  SessionOut,
} from "../Utils/utils";

const ListOfStocks = () => {
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);

  const [dataLoding, setDataLoading] = React.useState(false);
  const token = authTokenHeader();
  const serviceUrl = ServiceURL();

  const [listStockData, setListStockData] = React.useState([]);

  useEffect(() => {
    const fetchingWorkShop = async () => {
      setDataLoading(true);
      const options = {
        method: "Get",
        headers: { "Content-Type": "application/json", user_header_key: token },
      };
      const endpoint = serviceUrl + "stock/stocklist";
      await fetch(endpoint, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setListStockData(data.results);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              alert(data.message);
            }
          }
          setDataLoading(false);
        });
    };
    fetchingWorkShop();
  }, []);

  $(document).ready(function () {
    setTimeout(function () {
      $("#listOfAllStocks").DataTable();
    }, 1500);
  });

  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"allStocks"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>List Of All Stocks</h1>
            <hr />
          </div>
          {accessOnComponent ? (
            <section className="section dashboard">
              <>
                <div className="card mb-4">
                  <div className="card-body">
                    <h5 className="card-title">All Stocks</h5>
                    {dataLoding ? (
                      <LoaderShow />
                    ) : (
                      <>
                        <div className="mt-2">
                          <div class="table-responsive">
                            <table
                              className="table  datatable text-center"
                              id="listOfAllStocks"
                            >
                              <thead>
                                <tr>
                                  <th className="text-center" scope="col">
                                    #
                                  </th>
                                  <th className="text-center" scope="col">
                                    Stock Name
                                  </th>
                                  <th className="text-center" scope="col">
                                    Stock Qty
                                  </th>
                                  <th className="text-center" scope="col">
                                    Stock Available
                                  </th>
                                  <th className="text-center" scope="col">
                                    Stock Use
                                  </th>
                                  <th className="text-center" scope="col">
                                    Stock Price
                                  </th>
                                  <th className="text-center" scope="col">
                                    CreatedDate
                                  </th>
                                  <th className="text-center" scope="col">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {!listStockData ? (
                                  <>
                                    <b>No Data</b>
                                  </>
                                ) : (
                                  <>
                                    {listStockData.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <th scope="row">#{item.stock_id}</th>
                                          <td>
                                            <b>
                                              <Link
                                                to={`/stock-history/${item.stock_id}`}
                                                title={item.stock_name}
                                              >
                                                {item.stock_name}
                                              </Link>
                                            </b>
                                          </td>
                                          <td>
                                            <b>
                                              {formatNumberIn(
                                                item.stock_quantity
                                              )}
                                            </b>
                                          </td>
                                          <td>
                                            <b className="text-info">
                                              {formatNumberIn(
                                                item.stock_available
                                              )}
                                            </b>
                                          </td>
                                          <td>
                                            {item.stock_status === "0" ? (
                                              <b className="text-success">
                                                Use For Sale
                                              </b>
                                            ) : (
                                              <b className="text-danger">
                                                Not Use For Sale
                                              </b>
                                            )}
                                          </td>
                                          <td>
                                            {formatAmountInr(item.stock_price)}
                                          </td>
                                          <td>
                                            {formatDateDyMmYyyy(
                                              new Date(`${item.created}`)
                                            )}
                                          </td>
                                          <td>
                                            <Link
                                              style={{ marginRight: "12px" }}
                                              to={`/edit-stock/${item.stock_id}`}
                                            >
                                              <i className="bi bi-pencil mr-1" />{" "}
                                            </Link>
                                            {userRole === "superAdmin" ? (
                                              <Link
                                                className="ml-1"
                                                to={`/delete-stock/${item.stock_id}`}
                                              >
                                                <i className="bi bi-trash" />
                                              </Link>
                                            ) : null}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            </section>
          ) : (
            <AccessDenide />
          )}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default ListOfStocks;
