import React, { useEffect } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import AccessDenide from "../AccessDenide/AccessDenide";
import LoaderShow from "../Loader/LoaderShow";
import { Link } from "react-router-dom";
import {
  ServiceURL,
  authTokenHeader,
  formatAmountInr,
  formatDateDyMmYyyy,
  SessionOut,
} from "../Utils/utils";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Footer from "../Footer";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const ListOfDuesByCustomer = (customerMobile) => {
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);

  let params = useParams();
  const customerNumber = params.id;

  const [dataLoding, setDataLoading] = React.useState(false);

  const token = authTokenHeader();
  const serviceURl = ServiceURL();

  const [customerOldDues, setCustomerOldDues] = React.useState([]);
  const [customerName, setCustomerName] = React.useState("");

  useEffect(() => {
    const getOldDuesOfSameCustomer = async (custMobile) => {
      setDataLoading(true);
      const customerURl = serviceURl + `sales/due/getdues`;
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json", user_header_key: token },
        body: JSON.stringify({
          search_input: custMobile,
        }),
      };
      await fetch(customerURl, options)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setCustomerOldDues(data.results);
            setCustomerName(data.results[0]?.customerDetails[0]?.user_name);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              toast.error(data.message);
            }
          }
          setDataLoading(false);
        });
    };

    getOldDuesOfSameCustomer(customerNumber);
  }, []);

  $(document).ready(function () {
    setTimeout(function () {
      $("#listOfDues").DataTable();
    }, 1500);
  });

  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"allDues"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>
              List Of Dues - {customerNumber}{" "}
              {customerName !== "" ? "( " + customerName + " )" : ""}
            </h1>
            <hr />
          </div>
          {accessOnComponent ? (
            dataLoding ? (
              <LoaderShow />
            ) : (
              <>
                <section className="section dashboard">
                  <div className="row">
                    {/* Left side columns */}
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-12">
                          <div className="card recent-sales overflow-auto">
                            <div className="card-body">
                              <h5 className="card-title">
                                All Dues <span>| Total</span>
                              </h5>
                              <div class="table-responsive">
                                <table
                                  className="table  datatable text-center"
                                  id="listOfDues"
                                >
                                  <thead>
                                    <tr>
                                      <th className="text-center" scope="col">
                                        #
                                      </th>
                                      <th className="text-center" scope="col">
                                        CustomerMobile
                                      </th>
                                      <th className="text-center" scope="col">
                                        OrderProducts
                                      </th>
                                      <th className="text-center" scope="col">
                                        TotalAmount
                                      </th>
                                      <th className="text-center" scope="col">
                                        PaidAmount
                                      </th>
                                      <th className="text-center" scope="col">
                                        DueAmount
                                      </th>
                                      <th className="text-center" scope="col">
                                        CreatedDate
                                      </th>
                                      <th className="text-center" scope="col">
                                        Actions
                                      </th>
                                      <th className="text-center" scope="col">
                                        Invoice
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!customerOldDues ? (
                                      <>
                                        <div className="text-center mt-5">
                                          <b>No Dues Found!</b>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {customerOldDues.map((item, index) => {
                                          return (
                                            <tr key={index}>
                                              <th scope="row">
                                                <Link
                                                  to={`/view-order/${item.sale_id}`}
                                                >
                                                  #{item.sale_id} (
                                                  {item.order_type})
                                                </Link>
                                              </th>
                                              <td>{item.user_mobile}</td>
                                              <td>
                                                <Link
                                                  to={`/view-order/${item.sale_id}`}
                                                >
                                                  <b
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Open Order to View Products."
                                                    style={{
                                                      cursor: "pointer",
                                                      backgroundColor:
                                                        "#f1f1f1",
                                                      padding: "5px",
                                                      borderRadius: "5px",
                                                      color: "#000",
                                                    }}
                                                  >
                                                    {item.productDetails.length}
                                                  </b>
                                                </Link>
                                              </td>
                                              <td>
                                                <b className="baseColor">
                                                  {formatAmountInr(
                                                    item.grand_total
                                                  )}
                                                </b>
                                              </td>
                                              <td>
                                                <b className="text-success">
                                                  {formatAmountInr(
                                                    item.paid_amount
                                                  )}
                                                </b>
                                              </td>
                                              <td>
                                                <b className="text-danger">
                                                  {formatAmountInr(
                                                    item.due_amount
                                                  )}
                                                </b>
                                              </td>
                                              <td>
                                                {formatDateDyMmYyyy(
                                                  new Date(`${item.created}`)
                                                )}
                                              </td>
                                              <td>
                                                <Link
                                                  style={{
                                                    marginRight: "12px",
                                                  }}
                                                  to={
                                                    item.order_type === "W"
                                                      ? `/update-order/${item.sale_id}`
                                                      : `/update-order-retail/${item.sale_id}`
                                                  }
                                                >
                                                  <i className="bi bi-pencil mr-1" />{" "}
                                                </Link>
                                                {userRole === "superAdmin" ? (
                                                  <Link
                                                    className="ml-1"
                                                    to={`/delete-order/${item.sale_id}`}
                                                  >
                                                    <i className="bi bi-trash" />
                                                  </Link>
                                                ) : null}
                                              </td>
                                              <td>
                                                <Link
                                                  target="_blank"
                                                  to={
                                                    item.order_type === "W"
                                                      ? `/view-invoice/${item.sale_id}`
                                                      : `/invoice-order-view/${item.sale_id}`
                                                  }
                                                >
                                                  <i class="bi bi-file-earmark-pdf"></i>{" "}
                                                  Invoice
                                                </Link>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* End Recent Sales */}
                      </div>
                    </div>
                    {/* End Left side columns */}
                  </div>
                </section>
              </>
            )
          ) : (
            <AccessDenide />
          )}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default ListOfDuesByCustomer;
