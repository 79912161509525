import React, { useEffect, useState } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import AccessDenide from "../AccessDenide/AccessDenide";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Footer from "../Footer";
import {
  ServiceURL,
  authTokenHeader,
  todayDateString,
  SessionOut,
} from "../Utils/utils";
import { useNavigate } from "react-router-dom";

const AddNewWork = () => {
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);

  const [listOfWorkshop, setListOfWorkshop] = React.useState([]);

  const token = authTokenHeader();
  const serviceUrl = ServiceURL();
  const navigator = useNavigate();

  useEffect(() => {
    const fetchingWorkShop = async () => {
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json", user_header_key: token },
      };
      const workshopEndpoint = serviceUrl + "workshop/list";
      await fetch(workshopEndpoint, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setListOfWorkshop(data.results);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            }
            setListOfWorkshop(data.results);
          }
        });
    };
    fetchingWorkShop();
  }, []);
  const [workShopId, setWorkShopId] = useState("");
  const [workForwardedWt, setWorkForwardedWt] = useState(0);
  const [workReceviedWt, setWorkRecevidedWt] = useState(0);
  const [workStatus, setWorkStatus] = useState(1);

  const addNewWork = async (e) => {
    e.preventDefault();
    const addNewWork = {
      workshop_id: workShopId,
      forwared_wt: workForwardedWt,
      received_wt: workReceviedWt,
      workdata_status: workStatus,
    };
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json", user_header_key: token },
      body: JSON.stringify(addNewWork),
    };
    const endpoint = serviceUrl + "workdata/create";
    await fetch(endpoint, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert(data.message);
          navigator("/list-of-works");
        } else {
          if (data.message === "invalidToken") {
            SessionOut("invalidToken");
          } else if (data.message === "internalServerError") {
            SessionOut("internalServerError");
          } else {
            alert(data.message);
          }
        }
      });
  };

  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"addNewWork"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Add Work</h1>
            <hr />
          </div>
          {accessOnComponent ? (
            <section className="section dashboard">
              <>
                <div className="card mb-4">
                  <div className="card-body">
                    <h5 className="card-title">
                      Add New WorkShop Work{" "}
                      <span>| {todayDateString(new Date())}</span>
                    </h5>
                    <form onSubmit={addNewWork}>
                      <div className="row mt-1">
                        <div className="col-md-6">
                          <select
                            className="form-select"
                            onChange={(e) => {
                              setWorkShopId(e.target.value);
                            }}
                            required
                          >
                            <option value="">Select WorkShop</option>
                            {listOfWorkshop.map((item, index) => {
                              return (
                                <>
                                  <option key={index} value={item.workshop_id}>
                                    {item.workshop_name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Forwarded WT (eg. 7.56)"
                              step="any"
                              value={workForwardedWt}
                              onChange={(e) => {
                                setWorkForwardedWt(e.target.value);
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control"
                              step="any"
                              placeholder="Enter Received WT (eg. 7.20)"
                              value={workReceviedWt}
                              onChange={(e) => {
                                setWorkRecevidedWt(e.target.value);
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <select
                            className="form-select"
                            onChange={(e) => {
                              setWorkStatus(e.target.value);
                            }}
                            value={workStatus}
                            required
                          >
                            <option value="1">Pending Item</option>
                            <option value="0">Received Item</option>
                          </select>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="mt-1 btn baseBackGroundColor btn-block"
                      >
                        Add New Work
                      </button>
                    </form>
                  </div>
                </div>
              </>
            </section>
          ) : (
            <AccessDenide />
          )}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default AddNewWork;
