import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Account/Login.js";
import Dashboard from "./components/Dashboard/Dashboard.js";
import AccountSettings from "./components/Account/AccountSettings.js";
import Logout from "./components/Account/Logout.js";
import UpdateOrderDetails from "./components/Order/UpdateOrderDetails.js";
import OrderDetailsView from "./components/Order/OrderDetailsView.js";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute.js";
import AddNewOrder from "./components/Order/AddNewOrder.js";
import DeleteOrder from "./components/Order/DeleteOrder.js";
import ListOfDues from "./components/ListDues/ListOfDues.js";
import SearchSaleCustomer from "./components/SearchComponents/SearchSaleCustomer.js";
import ListOfAllCustomers from "./components/ListCustomers/ListOfCustomers.js";
import ListOfExpanses from "./components/Expanses/ListOfExpanses.js";
import ListOfWorkShopData from "./components/WorkShop/ListOfWorkShopData.js";
import ListOfAllInvoices from "./components/Invoices/ListOfAllInvoices.js";
import ListOfAllPayments from "./components/PaymentTransactions/ListOfAllPayments.js";
import AccessDenide from "./components/AccessDenide/AccessDenide.js";
import AddNewExpanse from "./components/Expanses/AddNewExpanse.js";
import AddNewWork from "./components/WorkShop/AddNewWork.js";
import AddNewWorkShop from "./components/WorkShop/AddNewWorkshop.js";
import UpdateExpanseDetails from "./components/Expanses/UpdateExpanse.js";
import DeleteExpanseDetails from "./components/Expanses/DeleteExpanse.js";
import EditAddedWork from "./components/WorkShop/EditAddedWork.js";
import DeleteAddedWork from "./components/WorkShop/DeleteAddedWork.js";
import ViewInvoice from "./components/Invoices/ViewInvoice.js";
import UpgradeVersion from "./components/Upgrade/UpgradeVersion.js";
import ListOfDuesByCustomer from "./components/ListDues/ListOfDuesByCustomer.js";
import AddNewStock from "./components/Stocks/AddNewStock.js";
import ListOfStocks from "./components/Stocks/ListOfStocks.js";
import DeleteStock from "./components/Stocks/DeleteStock.js";
import EditStock from "./components/Stocks/EditStocks.js";
import StockHistorySolo from "./components/Stocks/stockHistory.js";
import InvoiceOrderDetails from "./components/InvoiceOrder/CreateInvoiceOrder.js";
import ViewInvoiceOrder from "./components/InvoiceOrder/OfficeInvoice.js";
import AddNewOrderRetail from "./components/Order/AddNewOrderRetail.js";
import UpdateOrderRetail from "./components/Order/UpdateOrderDetailsRetail.js";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/access-denied" element={<AccessDenide />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile-settings"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <AccountSettings />
              </PrivateRoute>
            }
          />
          <Route
            path="/logout"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <Logout />
              </PrivateRoute>
            }
          />
          <Route
            path="/update-order/:id"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <UpdateOrderDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/view-order/:id"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <OrderDetailsView />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-order"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <AddNewOrder />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-order-retail"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <AddNewOrderRetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/update-order-retail/:id"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <UpdateOrderRetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/delete-order/:id"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <DeleteOrder />
              </PrivateRoute>
            }
          />
          <Route
            path="/invoice-order/:id"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <InvoiceOrderDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/invoice-order-view/:id"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <ViewInvoiceOrder />
              </PrivateRoute>
            }
          />
          <Route
            path="/list-dues"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <ListOfDues />
              </PrivateRoute>
            }
          />
          s
          <Route
            path="/search-sale-customer"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <SearchSaleCustomer />
              </PrivateRoute>
            }
          />
          <Route
            path="/list-customers"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <ListOfAllCustomers />
              </PrivateRoute>
            }
          />
          <Route
            path="/list-expanses"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <ListOfExpanses />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-expanse"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <AddNewExpanse />
              </PrivateRoute>
            }
          />
          <Route
            path="/update-expanse/:id"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <UpdateExpanseDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/delete-expanse/:id"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <DeleteExpanseDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/list-of-works"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <ListOfWorkShopData />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-new-work"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <AddNewWork />
              </PrivateRoute>
            }
          />
          <Route
            path="/update-work/:id"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <EditAddedWork />
              </PrivateRoute>
            }
          />
          <Route
            path="/delete-work/:id"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <DeleteAddedWork />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-new-workshop"
            element={
              <PrivateRoute allowedRoles={["superAdmin"]}>
                <AddNewWorkShop />
              </PrivateRoute>
            }
          />
          <Route
            path="/bills-invoices"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <ListOfAllInvoices />
              </PrivateRoute>
            }
          />
          <Route
            path="/view-invoice/:id"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <ViewInvoice />
              </PrivateRoute>
            }
          />
          <Route
            path="/payment-transactions"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <ListOfAllPayments />
              </PrivateRoute>
            }
          />
          <Route
            path="/upgrade-new-version"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <UpgradeVersion />
              </PrivateRoute>
            }
          />
          <Route
            path="/customerdues/:id"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <ListOfDuesByCustomer />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-stock"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <AddNewStock />
              </PrivateRoute>
            }
          />
          <Route
            path="/list-stocks"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <ListOfStocks />
              </PrivateRoute>
            }
          />
          <Route
            path="/stock-history/:id"
            element={
              <PrivateRoute allowedRoles={["superAdmin"]}>
                <StockHistorySolo />
              </PrivateRoute>
            }
          />
          <Route
            path="/delete-stock/:id"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <DeleteStock />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-stock/:id"
            element={
              <PrivateRoute allowedRoles={["admin", "superAdmin"]}>
                <EditStock />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
