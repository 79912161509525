import React, { useEffect } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import AccessDenide from "../AccessDenide/AccessDenide";
import LoaderShow from "../Loader/LoaderShow";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link, useParams } from "react-router-dom";
import Footer from "../Footer";
import {
  ServiceURL,
  authTokenHeader,
  formatAmountInr,
  formatNumberIn,
  formatDateDyMmYyyy,
  SessionOut,
} from "../Utils/utils";

const StockHistorySolo = () => {
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);

  let params = useParams();
  const stockId = params.id;

  const [dataLoding, setDataLoading] = React.useState(false);
  const token = authTokenHeader();
  const serviceUrl = ServiceURL();

  const [listStockData, setListStockData] = React.useState([]);
  const [stockName, setStockName] = React.useState("");
  const [stockIdIs, setStockIdIs] = React.useState("");

  useEffect(() => {
    const fetchingWorkShop = async () => {
      setDataLoading(true);
      const options = {
        method: "Get",
        headers: { "Content-Type": "application/json", user_header_key: token },
      };
      const endpoint = serviceUrl + `sales/stockusage/${stockId}`;
      await fetch(endpoint, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            if (data.count > 0) {
              setListStockData(data.saleResults);
              setStockName(data.saleResults[0].productDetails[0].product_name);
              setStockIdIs(stockId);
            } else {
              setListStockData([]);
              alert("No Data Found!");
              window.location.href = "/list-stocks";
            }
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              alert(data.message);
            }
          }
          setDataLoading(false);
        });
    };
    fetchingWorkShop();
  }, []);

  $(document).ready(function () {
    setTimeout(function () {
      $("#listOfAllStocks").DataTable();
    }, 1500);
  });

  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"allStocks"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>
              Stock History : {stockName} ( {stockId})
            </h1>
            <hr />
          </div>
          {accessOnComponent ? (
            <section className="section dashboard">
              <>
                <div className="card mb-4">
                  <div className="card-body">
                    <h5 className="card-title">
                      All <i>{stockName}</i> Used Grams History
                    </h5>
                    {dataLoding ? (
                      <LoaderShow />
                    ) : (
                      <>
                        <div className="mt-2">
                          <div class="table-responsive">
                            <table
                              className="table  datatable text-center"
                              id="listOfAllStocks"
                            >
                              <thead>
                                <tr>
                                  <th className="text-center" scope="col">
                                    Order Id
                                  </th>
                                  <th className="text-center" scope="col">
                                    CustomerName
                                  </th>
                                  <th className="text-center" scope="col">
                                    CustomerMobile
                                  </th>
                                  <th className="text-center" scope="col">
                                    NoOfGramsUsed
                                  </th>
                                  <th className="text-center" scope="col">
                                    ViewOrder
                                  </th>
                                  <th className="text-center" scope="col">
                                    CreatedDate
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {!listStockData ? (
                                  <>
                                    <b>No Data</b>
                                  </>
                                ) : (
                                  <>
                                    {listStockData.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>
                                            <b>
                                              <Link
                                                to={`/view-order/${item.sale_id}`}
                                                title={item.sale_id}
                                              >
                                                #{item.sale_id}
                                              </Link>
                                            </b>
                                          </td>
                                          <td>
                                            {item.customerDetails[0].user_name}
                                          </td>
                                          <td>
                                            {
                                              item.customerDetails[0]
                                                .user_mobile
                                            }
                                          </td>
                                          <td>
                                            <b className="text-primary">
                                              {item.no_of_gms} {""}{" "}
                                            </b>
                                            <small>Grams</small>
                                          </td>
                                          <td>
                                            <Link
                                              to={`/view-order/${item.sale_id}`}
                                              title={item.sale_id}
                                              className="btn btn-sm btn-info"
                                            >
                                              View Order
                                            </Link>
                                          </td>
                                          <td>
                                            {formatDateDyMmYyyy(
                                              new Date(`${item.created}`)
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            </section>
          ) : (
            <AccessDenide />
          )}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default StockHistorySolo;
