import React from "react";

const Footer = () => {
  return (
    <>
      <footer id="footer" class="footer">
        <div class="copyright">
          &copy; Copyright{" "}
          <strong>
            <span className="baseColor">Seetharam Jewellers & Works</span>
          </strong>
          . All Rights Reserved
        </div>
        <div class="credits p-2">
          Version : <b>2024.11</b> | Developed & Maintaining by{" "}
          <a href="https://ganeshbondla.in">
            <b>Ganesh Bondla</b>
          </a>
          <br />
          <small>
            DevelopmentStack : HTML, CSS, Javascript, Bootstrtap 4/5, ReactJS,
            NodeJS, ExpressJS, MySQL
          </small>
          <br />
          <small>Template & License from BootstrapMade</small>
        </div>
      </footer>
    </>
  );
};

export default Footer;
