import React, { useEffect } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import {
  formatAmountInr,
  ServiceURL,
  authTokenHeader,
  projectDetailsUrl,
  SessionOut,
} from "../Utils/utils";
import { Link, useNavigate } from "react-router-dom";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import AccessDenide from "../AccessDenide/AccessDenide";
import LoaderShow from "../Loader/LoaderShow";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Footer from "../Footer";
import { toast } from "react-toastify";

const Dashboard = () => {
  const navigate = useNavigate();
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);

  const [salesCountToday, setSalesCountToday] = React.useState(0);
  const [salesAmountToday, setSalesAmountToday] = React.useState(0);
  const [dueAmountToday, setDueAmountToday] = React.useState(0);

  const [salesCountTotal, setSalesCountTotal] = React.useState(0);
  const [salesAmountTotal, setSalesAmountTotal] = React.useState(0);
  const [dueAmountTotal, setDueAmountTotal] = React.useState(0);

  const [dataLoding, setDataLoading] = React.useState(false);
  const [loadingRecentSales, setLoadingRecentSales] = React.useState(false);

  const [recentSales, setRecentSales] = React.useState([]);

  //ServiceCall(API Call) Requires.
  const ServiceCallURL = ServiceURL();
  const headerToken = authTokenHeader();

  useEffect(() => {
    //Dashboard Stats Service Call
    const DashboardStats = async () => {
      setDataLoading(true);
      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          user_header_key: headerToken,
        },
      };
      const endpoint = ServiceCallURL + "sales/stats";
      await fetch(endpoint, options)
        .then((res) => res.json())
        .then((data) => {
          if (data.success === false) {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              toast.error(data.message);
            }
          } else {
            //Today
            setSalesCountToday(data.todayResults[0].today_count);
            setSalesAmountToday(
              data.todayResults[0].today_sale_paid[0].amount === null
                ? 0
                : data.todayResults[0].today_sale_paid[0].amount
            );
            setDueAmountToday(
              data.todayResults[0].today_sale_due[0].amount === null
                ? 0
                : data.todayResults[0].today_sale_due[0].amount
            );
            //Total
            setSalesCountTotal(data.totalSalesResults[0].total_count);
            setSalesAmountTotal(
              data.totalSalesResults[0].total_sales_paid[0].amount === null
                ? 0
                : data.totalSalesResults[0].total_sales_paid[0].amount
            );
            setDueAmountTotal(
              data.totalSalesResults[0].total_sales_due[0].amount === null
                ? 0
                : data.totalSalesResults[0].total_sales_due[0].amount
            );
          }
          setDataLoading(false);
        });
    };

    //Latest 15 Sales Service Call
    const Last15Sales = async () => {
      setLoadingRecentSales(true);
      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          user_header_key: headerToken,
        },
        body: JSON.stringify({
          sales_count: 15,
        }),
      };
      const endpoint = ServiceCallURL + "sales/list";
      await fetch(endpoint, options)
        .then((res) => res.json())
        .then((data) => {
          if (data.success === false) {
            if (data.message === "invalidToken") {
              alert("Lost Session!, Login Again!");
              window.location.href = "/login";
            } else if (data.message === "internalServerError") {
              alert("Internal Server Error!, Login Again!");
              window.location.href = "/login";
            } else {
              toast.error(data.message);
            }
          } else {
            setRecentSales(data.saleResults);
            setLoadingRecentSales(false);
          }
        });
    };

    const fetchProjectDetails = async () => {
      setDataLoading(true);
      const fetchProjectDetailsUrl = projectDetailsUrl();
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      await fetch(fetchProjectDetailsUrl, options)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            localStorage.setItem("srjexpiredays", data.days_left);
            setDataLoading(false);
          } else {
            if (data.project_name === "No Record Found") {
              alert("Lost Session!, Login Again!");
              navigate("/login");
            } else if (data.project_name === "Invalid Request") {
              alert("Internal Server Error!, Login Again!");
              navigate("/login");
            } else {
              alert("Contact Developer @ 8074725033");
              navigator("/dashboard");
            }
          }
        });
    };

    //Action On Two Functions
    fetchProjectDetails();
    DashboardStats();
    Last15Sales();
  }, [ServiceCallURL, headerToken]);

  $(document).ready(function () {
    setTimeout(function () {
      $("#recentSales").DataTable();
    }, 1500);
  });

  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"dashboard"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Dashboard</h1>
            <hr />
          </div>
          {accessOnComponent ? (
            dataLoding ? (
              <LoaderShow />
            ) : (
              <>
                <section className="section dashboard">
                  <div className="row">
                    {/* Left side columns */}
                    <div className="col-lg-12">
                      <div className="row">
                        {/* Sales Card */}
                        <div className="col-xxl-4 col-md-4">
                          <div className="card info-card sales-card">
                            <div className="card-body">
                              <h5 className="card-title">
                                Sales <span>| Today</span>
                              </h5>
                              <div className="d-flex align-items-center">
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                  <i className="bi bi-cart" />
                                </div>
                                <div className="ps-3">
                                  <b style={{ fontSize: "21px" }}>
                                    {salesCountToday}
                                  </b>
                                  <br />
                                  <span className="text-muted small pt-2 ps-1">
                                    Today count in sales
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* End Sales Card */}
                        {/* Revenue Card */}
                        <div className="col-xxl-4 col-md-4">
                          <div className="card info-card revenue-card">
                            <div className="card-body">
                              <Link
                                to={`/bills-invoices?filter=today`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                <h5 className="card-title">
                                  Revenue <span>| Today</span>
                                </h5>
                                <div className="d-flex align-items-center">
                                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i className="bi bi-currency-rupee" />
                                  </div>
                                  <div className="ps-3">
                                    <b style={{ fontSize: "21px" }}>
                                      {formatAmountInr(salesAmountToday)}
                                    </b>
                                    <br />
                                    <span className="text-muted small pt-2 ps-1">
                                      Today total paid
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                        {/* End Revenue Card */}
                        {/* Customers Card */}
                        <div className="col-xxl-4 col-md-4">
                          <div className="card info-card revenue-card">
                            <div className="card-body">
                              <Link
                                to={`/list-dues?filter=today`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                <h5 className="card-title">
                                  Due <span>| Today</span>
                                </h5>
                                <div className="d-flex align-items-center">
                                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center text-danger">
                                    <i className="bi bi-currency-rupee" />
                                  </div>
                                  <div className="ps-3">
                                    <b style={{ fontSize: "21px" }}>
                                      {formatAmountInr(dueAmountToday)}
                                    </b>
                                    <br />
                                    <span className="text-muted small pt-2 ps-1">
                                      Today total due
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                        {/* End Customers Card */}
                        {/* Sales Card */}
                        <div className="col-xxl-4 col-md-4">
                          <div className="card info-card sales-card">
                            <div className="card-body">
                              <h5 className="card-title">
                                Sales <span>| Total</span>{" "}
                                <span>| Upto Date</span>
                              </h5>
                              <div className="d-flex align-items-center">
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                  <i className="bi bi-cart" />
                                </div>
                                <div className="ps-3">
                                  <b style={{ fontSize: "19px" }}>
                                    {salesCountTotal}
                                  </b>
                                  <br />
                                  <span className="text-muted small pt-2 ps-1">
                                    Total Count in sales
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* End Sales Card */}
                        {/* Revenue Card */}
                        <div className="col-xxl-4 col-md-4">
                          <div className="card info-card revenue-card">
                            <div className="card-body">
                              <Link
                                to="/bills-invoices"
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                <h5 className="card-title">
                                  Revenue <span>| Total</span>{" "}
                                  <span>| Upto Date</span>
                                </h5>
                                <div className="d-flex align-items-center">
                                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                    <i className="bi bi-currency-rupee" />
                                  </div>
                                  <div className="ps-3">
                                    <b style={{ fontSize: "19px" }}>
                                      {formatAmountInr(salesAmountTotal)}
                                    </b>
                                    <br />
                                    <span className="text-muted small pt-2 ps-1">
                                      Total paid amount
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                        {/* End Revenue Card */}
                        {/* Customers Card */}
                        <div className="col-xxl-4 col-md-4">
                          <div className="card info-card revenue-card">
                            <div className="card-body">
                              <Link
                                to="/list-dues"
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                <h5 className="card-title">
                                  Due <span>| Total</span>{" "}
                                  <span>| Upto Date</span>
                                </h5>
                                <div className="d-flex align-items-center">
                                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center text-danger">
                                    <i className="bi bi-currency-rupee" />
                                  </div>
                                  <div className="ps-3">
                                    <b style={{ fontSize: "19px" }}>
                                      {formatAmountInr(dueAmountTotal)}
                                    </b>
                                    <br />
                                    <span className="text-muted small pt-2 ps-1">
                                      Total due amount
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                        {/* End Customers Card */}
                        {/* Recent Sales */}
                        {loadingRecentSales ? (
                          <LoaderShow />
                        ) : (
                          <>
                            <div className="col-12">
                              <div className="card recent-sales overflow-auto">
                                <div className="card-body">
                                  <h5 className="card-title">
                                    Recent Sales <span>| Today</span>
                                  </h5>
                                  <div class="table-responsive">
                                    <table
                                      className="table text-center"
                                      id="recentSales"
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            className="text-center"
                                            scope="col"
                                          >
                                            #
                                          </th>
                                          <th
                                            className="text-center"
                                            scope="col"
                                          >
                                            CustomerName
                                          </th>
                                          <th
                                            className="text-center"
                                            scope="col"
                                          >
                                            CustomerMobile
                                          </th>
                                          <th
                                            className="text-center"
                                            scope="col"
                                          >
                                            Order.Products
                                          </th>
                                          <th
                                            className="text-center"
                                            scope="col"
                                          >
                                            Order.Total
                                          </th>
                                          <th
                                            className="text-center"
                                            scope="col"
                                          >
                                            Status
                                          </th>
                                          <th
                                            className="text-center"
                                            scope="col"
                                          >
                                            Actions
                                          </th>
                                          <th
                                            className="text-center"
                                            scope="col"
                                          >
                                            Invoice
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {!recentSales ? (
                                          <>
                                            <div className="text-center mt-5">
                                              <b>No Sales Found!</b>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            {recentSales.map((item, index) => {
                                              return (
                                                <tr key={index}>
                                                  <th scope="row">
                                                    <Link
                                                      to={`/view-order/${item.sale_id}`}
                                                    >
                                                      #{item.sale_id} (
                                                      {item.order_type})
                                                    </Link>
                                                  </th>
                                                  <td>
                                                    {
                                                      item.customerDetails[0]
                                                        .user_name
                                                    }
                                                  </td>
                                                  <td>
                                                    {
                                                      item.customerDetails[0]
                                                        .user_mobile
                                                    }
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to={`/view-order/${item.sale_id}`}
                                                    >
                                                      <b
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="Open Order to View Products."
                                                        style={{
                                                          cursor: "pointer",
                                                          backgroundColor:
                                                            "#f1f1f1",
                                                          padding: "5px",
                                                          borderRadius: "5px",
                                                          color: "#000",
                                                        }}
                                                      >
                                                        {
                                                          item.productDetails
                                                            .length
                                                        }
                                                      </b>
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    {formatAmountInr(
                                                      item.grand_total
                                                    )}
                                                  </td>
                                                  <td>
                                                    {item.sale_status === 1 ? (
                                                      <span className="badge bg-success">
                                                        Paid
                                                      </span>
                                                    ) : (
                                                      <span className="badge bg-danger">
                                                        Due
                                                      </span>
                                                    )}
                                                  </td>
                                                  <td>
                                                    <Link
                                                      style={{
                                                        marginRight: "12px",
                                                      }}
                                                      to={
                                                        item.order_type === "W"
                                                          ? `/update-order/${item.sale_id}`
                                                          : `/update-order-retail/${item.sale_id}`
                                                      }
                                                    >
                                                      <i className="bi bi-pencil mr-1" />{" "}
                                                    </Link>
                                                    {userRole ===
                                                    "superAdmin" ? (
                                                      <Link
                                                        className="ml-1"
                                                        to={`/delete-order/${item.sale_id}`}
                                                      >
                                                        <i className="bi bi-trash" />
                                                      </Link>
                                                    ) : null}
                                                  </td>
                                                  <td>
                                                    <Link
                                                      target="_blank"
                                                      to={
                                                        item.order_type === "W"
                                                          ? `/view-invoice/${item.sale_id}`
                                                          : `/invoice-order-view/${item.sale_id}`
                                                      }
                                                    >
                                                      <i class="bi bi-file-earmark-pdf"></i>{" "}
                                                      Invoice
                                                    </Link>
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                          </>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {/* End Recent Sales */}
                      </div>
                    </div>
                    {/* End Left side columns */}
                  </div>
                </section>
              </>
            )
          ) : (
            <AccessDenide />
          )}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default Dashboard;
