import React, { useEffect } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import AccessDenide from "../AccessDenide/AccessDenide";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Footer from "../Footer";
import {
  ServiceURL,
  authTokenHeader,
  todayDateString,
  SessionOut,
} from "../Utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EditStock = () => {
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);

  let params = useParams();
  const stockId = params.id;

  const [dataLoading, setDataLoading] = React.useState(false);

  const [stockName, setStockName] = React.useState("");
  const [stockStatus, setStockStatus] = React.useState("");

  const token = authTokenHeader();
  const serviceurl = ServiceURL();
  const navigator = useNavigate();

  const deleteStock = async (e) => {
    e.preventDefault();
    let options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        user_header_key: token,
      },
    };
    const endpoint = serviceurl + `stock/delete/${stockId}`;
    await fetch(endpoint, options)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert(data.message);
          navigator("/list-stocks");
        } else {
          if (data.message === "invalidToken") {
            SessionOut("invalidToken");
          } else if (data.message === "internalServerError") {
            SessionOut("internalServerError");
          } else {
            toast.error(data.message);
          }
        }
      });
  };

  useEffect(() => {
    const getStockDetails = async () => {
      setDataLoading(true);
      const endpoint = serviceurl + `stock/${stockId}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          user_header_key: token,
        },
      };
      await fetch(endpoint, options)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setStockName(data.results[0].stock_name);
            setStockStatus(data.results[0].stock_status);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              alert(data.message);
              navigator("/list-expanses");
            }
          }
          setDataLoading(false);
        });
    };
    getStockDetails();
  }, []);

  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"allStocks"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Update Stock</h1>
            <hr />
          </div>
          {accessOnComponent ? (
            <section className="section dashboard">
              <>
                <div className="card mb-4">
                  <div className="card-body">
                    <h5 className="card-title">
                      Update Stock <span>| {todayDateString(new Date())}</span>
                    </h5>
                    <form onSubmit={deleteStock}>
                      <div className="row mt-1">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Enter Stock Name"
                              value={stockName}
                              onChange={(e) => setStockName(e.target.value)}
                              required
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <select
                            className="form-select"
                            onChange={(e) => setStockStatus(e.target.value)}
                            required
                            defaultValue={stockStatus}
                            disabled={true}
                          >
                            <option value="">Select Stock Status</option>
                            <option
                              value="0"
                              selected={stockStatus === "0" ? "selected" : ""}
                            >
                              Use For Sale
                            </option>
                            <option
                              value="1"
                              selected={stockStatus === "1" ? "selected" : ""}
                            >
                              Dont Use For Sale
                            </option>
                          </select>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="mt-1 btn btn-danger btn-block"
                      >
                        Delete Now
                      </button>
                    </form>
                  </div>
                </div>
              </>
            </section>
          ) : (
            <AccessDenide />
          )}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default EditStock;
