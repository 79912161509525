import React, { useEffect, useState } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import AccessDenide from "../AccessDenide/AccessDenide";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Footer from "../Footer";
import $ from "jquery";
import {
  ServiceURL,
  authTokenHeader,
  todayDateString,
  SessionOut,
} from "../Utils/utils";
import LoaderShow from "../Loader/LoaderShow";
import { Link } from "react-router-dom";

const AddNewWorkShop = () => {
  const { accessOnComponent, userRole, UserName } =
    React.useContext(RoleContext);

  const [dataLoding, setDataLoading] = React.useState(false);

  const [allWorkShops, setAllWorkshops] = React.useState([]);
  const [workShopName, setWorkShopName] = useState("");
  const [workShopNumber, setWorkShopNumber] = useState("");
  const token = authTokenHeader();
  const serviceUrl = ServiceURL();

  const addNewWorkShop = (e) => {
    e.preventDefault();
    const addNewWorkShop = {
      workshop_name: workShopName,
      workshop_number: workShopNumber,
    };
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json", user_header_key: token },
      body: JSON.stringify(addNewWorkShop),
    };
    const endpoint = serviceUrl + "workshop/create";
    fetch(endpoint, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert(data.message);
          fetchingWorkshops();
          clearInpuStates();
        } else {
          if (data.message === "invalidToken") {
            SessionOut("invalidToken");
          } else if (data.message === "internalServerError") {
            SessionOut("internalServerError");
          } else {
            alert(data.message);
          }
        }
      });
  };

  const clearInpuStates = () => {
    setWorkShopName("");
    setWorkShopNumber("");
  };

  $(document).ready(function () {
    setTimeout(function () {
      $("#listOfAllWorkShops").DataTable();
    }, 1500);
  });

  const fetchingWorkshops = async () => {
    setDataLoading(true);
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json", user_header_key: token },
    };
    const endpoint = serviceUrl + "workshop/list";
    fetch(endpoint, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setAllWorkshops(data.results);
        } else {
          if (data.message === "invalidToken") {
            SessionOut("invalidToken");
          } else if (data.message === "internalServerError") {
            SessionOut("internalServerError");
          } else {
            alert(data.message);
          }
        }
        setDataLoading(false);
      });
  };

  const deleteWorkShopFromList = (workshopid) => {
    const options = {
      method: "DELETE",
      headers: { "Content-Type": "application/json", user_header_key: token },
    };
    const endpoint = serviceUrl + `workshop/delete/${workshopid}`;
    fetch(endpoint, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert(data.message);
        } else {
          if (data.message === "invalidToken") {
            SessionOut("invalidToken");
          } else if (data.message === "internalServerError") {
            SessionOut("internalServerError");
          } else {
            alert(data.message);
          }
        }
        fetchingWorkshops();
      });
  };

  useEffect(() => {
    fetchingWorkshops();
  }, []);

  return (
    <>
      <Header userRole={userRole} userName={UserName} />
      <SideBar activeNav={"addNewWorkShop"} />
      <>
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Add New Work Shop</h1>
            <hr />
          </div>
          {accessOnComponent ? (
            <section className="section dashboard">
              <>
                <div className="card mb-4">
                  <div className="card-body">
                    <h5 className="card-title">
                      Add New WorkShop{" "}
                      <span>| {todayDateString(new Date())}</span>
                    </h5>
                    <form onSubmit={addNewWorkShop}>
                      <div className="row mt-1">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              step="any"
                              placeholder="Work Shop Name (eg. Ganesh Nani Shop)"
                              value={workShopName}
                              onChange={(e) => {
                                setWorkShopName(e.target.value);
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control"
                              step="any"
                              placeholder="Work Shop Mobile Number (eg. 8074725033)"
                              value={workShopNumber}
                              onChange={(e) => {
                                setWorkShopNumber(e.target.value);
                              }}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="mt-1 btn baseBackGroundColor btn-block"
                      >
                        Add New Work Shop
                      </button>
                    </form>
                    <hr />
                    <h5 className="card-title">List Of All WorkShops</h5>
                    {dataLoding ? (
                      <LoaderShow />
                    ) : (
                      <>
                        <div className="mt-2">
                          <div class="table-responsive">
                            <table
                              className="table datatable text-center"
                              id="listOfAllWorkShops"
                            >
                              <thead>
                                <tr>
                                  <th className="text-center" scope="col">
                                    #
                                  </th>
                                  <th className="text-center" scope="col">
                                    WorkShopName
                                  </th>
                                  <th className="text-center" scope="col">
                                    WorkShopNumber
                                  </th>
                                  <th className="text-center" scope="col">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {!allWorkShops ? (
                                  <>
                                    <b>No Data</b>
                                  </>
                                ) : (
                                  <>
                                    {allWorkShops.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <th scope="row">
                                            #{item.workshop_id}
                                          </th>
                                          <td>
                                            <Link
                                              to="#"
                                              title={item.workshop_name}
                                            >
                                              {item.workshop_name}
                                            </Link>
                                          </td>
                                          <td>{item.workshop_number}</td>
                                          <td>
                                            {userRole === "superAdmin" ? (
                                              <Link
                                                className="ml-1"
                                                onClick={() => {
                                                  deleteWorkShopFromList(
                                                    item.workshop_id
                                                  );
                                                }}
                                              >
                                                <i className="bi bi-trash" />
                                              </Link>
                                            ) : (
                                              "NoAccess"
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            </section>
          ) : (
            <AccessDenide />
          )}
        </main>
        {/* End #main */}
      </>
      <Footer />
    </>
  );
};

export default AddNewWorkShop;
