import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import AccessDenide from "../AccessDenide/AccessDenide";
import LoaderShow from "../Loader/LoaderShow";
import { RoleContext } from "../PrivateRoute/PrivateRoute";
import {
  formatAmountInr,
  formatNumberIn,
  authTokenHeader,
  ServiceURL,
  getFlooredFixed,
  formatNumberNoDacimal,
  formatDateDyMmYyyy,
  SessionOut,
} from "../Utils/utils";

const ViewInvoice = () => {
  const { accessOnComponent } = React.useContext(RoleContext);
  const [dataLoding, setDataLoading] = React.useState(false);
  const [orderDetails, setOrderDetails] = React.useState([]);
  const [visiblePrintButton, setVisiblePrintButton] = React.useState(true);
  let params = useParams();
  const orderId = params.id;

  const token = authTokenHeader();
  const serviceURl = ServiceURL();

  const onCLickPrintPage = () => {
    setVisiblePrintButton(false);
    setTimeout(function () {
      window.print();
    }, 1000);
  };

  useEffect(() => {
    const getOrderDetails = async () => {
      setDataLoading(true);
      const endpoint = serviceURl + `sales/${orderId}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          user_header_key: token,
        },
      };
      await fetch(endpoint, options)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setOrderDetails(data.results);
          } else {
            if (data.message === "invalidToken") {
              SessionOut("invalidToken");
            } else if (data.message === "internalServerError") {
              SessionOut("internalServerError");
            } else {
              alert(data.message);
            }
          }
          setDataLoading(false);
        });
    };
    getOrderDetails();
  }, []);

  const returnView = orderDetails.map((item, index) => {
    const totalWithExtraAmount = item.total_price + item.extra_product_amount;

    const taxableAmount = totalWithExtraAmount - item.discount_amount;

    const afterTaxTotal = taxableAmount + item.tax_total;

    const afterExchangeAmountTotal =
      afterTaxTotal - (item.exchangeDetails[0]?.ex_amount || 0);

    const totalAmountProducts = item.productDetails.reduce(
      (accumulator, object) => {
        return accumulator + object.total_amount;
      },
      0
    );

    return (
      <>
        <div className="container mt-1 mainContent" key={index}>
          <div class="watermark__inner">
            <div class="watermark__body text-center">
              Seetharam Jewellers
              <br />
              Armoor
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-lg-3">
              <img
                src="/images/logo.jpg"
                alt="logo"
                height={"160px"}
                width={"230px"}
              />
            </div>
            <div className="col-md-9 col-lg-9 text-left my-auto">
              <h1 style={{ color: "#B01218" }}>
                <b>Seetharam Jewellers & Works</b>
              </h1>
              <p>Armoor, Husnaband street, 503224, Nizamabad, Telanagana.</p>
              <p>
                <b>Contacts : </b>
                S.Ranjith: <b>9494720445</b>, S.Ajay: <b>7036064215</b>
                <br />
              </p>
            </div>
          </div>
          <hr
            style={{
              border: "2px solid #B01218",
              marginTop: "9px",
              marginBottom: "0px",
            }}
          ></hr>
          <div className="row p-2">
            <div className="col-md-4 col-lg-4 text-left">
              <b>Invoice No: </b> {orderId}
            </div>
            <div className="col-md-4 col-lg-4 text-left">
              <b>Invoice Date: </b>{" "}
              {formatDateDyMmYyyy(new Date(`${item.created}`))}
            </div>
            <div className="col-md-4 col-lg-4 text-left">
              <b>Dated One Gram Price : </b>{" "}
              {formatAmountInr(item.productDetails[0].one_gm_price)}
            </div>
          </div>
          <hr
            style={{
              border: "1px solid #B01218",
              marginTop: "0px",
              marginBottom: "0px",
            }}
          ></hr>
          <div className="row p-2">
            <div className="col-md-3 col-lg-3 text-left">
              <b>To : </b>
              {item.customerDetails[0].user_name}
              <br></br>
              <b>Payment Method : </b>{" "}
              {item.payment_method === "C"
                ? "CASH"
                : item.payment_method === "O"
                ? "ONLINE"
                : item.payment_method === "ND"
                ? "NO DECISION"
                : "UPI"}
            </div>
            <div className="col-md-3 col-lg-3 text-left">
              <b>Mobile: </b>
              {item.customerDetails[0].user_mobile}
              <br></br>
              <b>Paid Amount : </b>
              {formatAmountInr(item.paid_amount)}
            </div>
            <div className="col-md-3 col-lg-3 text-left my-auto">
              <b>Address : </b>
              {item.customerDetails[0].user_address}
              <br></br>
              <b>Due Amount : </b>
              {formatAmountInr(item.due_amount)}
            </div>
            <div className="col-md-3 col-lg-3 text-left">
              <b>CustomerID : </b>
              {item.customerDetails[0].user_id}
              <br></br>
              <b>Payment Status : </b>{" "}
              <u>
                {item.sale_status === 1 ? (
                  "PAID"
                ) : (
                  <b style={{ fontSize: "14px", color: "#B01218" }}>
                    DUE : {formatAmountInr(item.due_amount)}
                  </b>
                )}
              </u>
            </div>
          </div>
          <div className="row mt-1 p-1">
            <div className="col-md-12 col-lg-12">
              <table
                className="table text-center table-border"
                style={{ padding: "0px", opacity: "0.7" }}
              >
                <tr>
                  <th
                    style={{
                      padding: "4px",
                      borderBottom: "1px solid #B01218",
                      borderTop: "1px solid #B01218",
                    }}
                  >
                    P. NAME
                  </th>
                  <th
                    style={{
                      padding: "4px",
                      borderBottom: "1px solid #B01218",
                      borderTop: "1px solid #B01218",
                    }}
                  >
                    NO OF GRAMS
                  </th>
                  <th
                    style={{
                      padding: "4px",
                      borderBottom: "1px solid #B01218",
                      borderTop: "1px solid #B01218",
                    }}
                  >
                    WASTAGE GRAMS
                  </th>
                  <th
                    style={{
                      padding: "4px",
                      borderBottom: "1px solid #B01218",
                      borderTop: "1px solid #B01218",
                    }}
                  >
                    MELTING @
                  </th>
                  <th
                    style={{
                      padding: "4px",
                      borderBottom: "1px solid #B01218",
                      borderTop: "1px solid #B01218",
                    }}
                  >
                    PURITY @
                  </th>
                  <th
                    style={{
                      padding: "4px",
                      borderBottom: "1px solid #B01218",
                      borderTop: "1px solid #B01218",
                    }}
                  >
                    MAKING. AMT
                  </th>
                  <th
                    style={{
                      padding: "4px",
                      borderBottom: "1px solid #B01218",
                      borderTop: "1px solid #B01218",
                    }}
                  >
                    ITEM STATUS
                  </th>
                  <th
                    style={{
                      padding: "4px",
                      borderBottom: "1px solid #B01218",
                      borderTop: "1px solid #B01218",
                    }}
                  >
                    TOTAL. AMT
                  </th>
                </tr>
                {item.productDetails.map((productItem, i) => (
                  <tr>
                    <td style={{ padding: "4px" }}>
                      {productItem.product_name}
                    </td>
                    <td style={{ padding: "4px" }}>
                      {getFlooredFixed(productItem.no_of_gms, 3)}
                    </td>
                    <td style={{ padding: "4px" }}>
                      {getFlooredFixed(productItem.wastage_gms, 3)}
                    </td>
                    <td style={{ padding: "4px" }}>
                      {productItem.melting_at > 0
                        ? `${Number(productItem.melting_at)}%`
                        : "--"}
                    </td>
                    <td style={{ padding: "4px" }}>
                      {productItem.purity_at > 0
                        ? getFlooredFixed(productItem.purity_at, 3)
                        : "--"}
                    </td>
                    <td style={{ padding: "4px" }}>
                      {getFlooredFixed(productItem.making_amount, 2)}
                    </td>
                    <td style={{ padding: "4px" }}>
                      {productItem.item_status === "0" ? "PAID" : "DUE"}
                    </td>
                    <td style={{ padding: "4px" }}>
                      <b>₹{formatNumberNoDacimal(productItem.total_amount)}</b>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-md-8 p-2">
              {item.extra_product_amount > 0 ? (
                <>
                  <table className="text-center">
                    <tr>
                      <td colSpan={7}>
                        Other <b>:-</b>{" "}
                      </td>
                      <td> {item.extra_product_desc}</td>
                    </tr>
                  </table>
                </>
              ) : (
                <></>
              )}
              {item.exchangeDetails[0]?.ex_amount || 0 > 0 ? (
                <>
                  <table
                    className="mt-2 table text-center table-border"
                    style={{ padding: "0px", opacity: "0.9" }}
                  >
                    <tr>
                      <th
                        style={{
                          padding: "4px",
                          borderBottom: "1px solid #B01218",
                          borderTop: "1px solid #B01218",
                        }}
                      >
                        OLD/EXCHANGE
                      </th>
                      <th
                        style={{
                          padding: "4px",
                          borderBottom: "1px solid #B01218",
                          borderTop: "1px solid #B01218",
                        }}
                      >
                        G. RATE
                      </th>
                      <th
                        style={{
                          padding: "4px",
                          borderBottom: "1px solid #B01218",
                          borderTop: "1px solid #B01218",
                        }}
                      >
                        G. WT
                      </th>
                      <th
                        style={{
                          padding: "4px",
                          borderBottom: "1px solid #B01218",
                          borderTop: "1px solid #B01218",
                        }}
                      >
                        MELTING @
                      </th>
                      <th
                        style={{
                          padding: "4px",
                          borderBottom: "1px solid #B01218",
                          borderTop: "1px solid #B01218",
                        }}
                      >
                        PURITY @
                      </th>
                      <th
                        style={{
                          padding: "4px",
                          borderBottom: "1px solid #B01218",
                          borderTop: "1px solid #B01218",
                        }}
                      >
                        NET. WT
                      </th>
                      <th
                        style={{
                          padding: "4px",
                          borderBottom: "1px solid #B01218",
                          borderTop: "1px solid #B01218",
                        }}
                      >
                        E.AMOUNT
                      </th>
                    </tr>
                    <tr>
                      <td style={{ padding: "4px" }}>
                        {item.exchangeDetails[0]?.ex_product_name}
                      </td>
                      <td style={{ padding: "4px" }}>
                        {formatAmountInr(
                          item.exchangeDetails[0]?.ex_one_gm_rate
                        )}
                      </td>
                      <td style={{ padding: "4px" }}>
                        {getFlooredFixed(item.exchangeDetails[0]?.ex_gms_wt, 3)}
                      </td>
                      <td style={{ padding: "4px" }}>
                        {`${Number(item.exchangeDetails[0]?.melting)}%`}
                      </td>
                      <td style={{ padding: "4px" }}>
                        {getFlooredFixed(item.exchangeDetails[0]?.ex_purity, 3)}
                      </td>
                      <td style={{ padding: "4px" }}>
                        {item.exchangeDetails[0]?.ex_netwt}
                      </td>
                      <td style={{ padding: "4px" }}>
                        <b>
                          ₹
                          {formatNumberNoDacimal(
                            item.exchangeDetails[0]?.ex_amount
                          )}
                        </b>
                      </td>
                    </tr>
                  </table>
                </>
              ) : (
                <></>
              )}

              <hr></hr>
              <div className="row mr-1 ml-1">
                <div className="col-6 p-2 text-left">
                  <b>NOTE: </b>
                </div>
                <div className="col-6 p-2 border-left text-center">
                  <b>AUTHORISED SIGN.</b>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-2">
              <table
                className="table text-left table-border"
                style={{ padding: "0px" }}
              >
                <tr>
                  <th style={{ padding: "5px" }}>Total Amount</th>
                  <td style={{ padding: "5px", textAlign: "right" }}>
                    {formatAmountInr(totalAmountProducts)}
                  </td>
                </tr>
                <tr>
                  <th style={{ padding: "5px" }}>Add: Other</th>
                  <td style={{ padding: "5px", textAlign: "right" }}>
                    {formatAmountInr(item.extra_product_amount)}
                  </td>
                </tr>
                <tr>
                  <th style={{ padding: "5px" }}>Less: Discount</th>
                  <td style={{ padding: "5px", textAlign: "right" }}>
                    <b>-</b> {formatAmountInr(item.discount_amount)}
                  </td>
                </tr>
                <tr>
                  <th style={{ padding: "5px" }}>Taxable Amount</th>
                  <td style={{ padding: "5px", textAlign: "right" }}>
                    <b>{formatAmountInr(taxableAmount)}</b>
                  </td>
                </tr>
                <tr>
                  <th style={{ padding: "5px" }}>GST Total</th>
                  <td style={{ padding: "5px", textAlign: "right" }}>
                    {formatAmountInr(item.tax_total)}
                  </td>
                </tr>
                <tr>
                  <th style={{ padding: "5px" }}>After Tax</th>
                  <td style={{ padding: "5px", textAlign: "right" }}>
                    <b>{formatAmountInr(afterTaxTotal)}</b>
                  </td>
                </tr>
                {item.exchangeDetails[0]?.ex_amount > 0 ? (
                  <>
                    <tr>
                      <th style={{ padding: "5px" }}>Less: O/E value</th>
                      <td style={{ padding: "5px", textAlign: "right" }}>
                        <b>-</b>{" "}
                        {formatAmountInr(item.exchangeDetails[0]?.ex_amount)}
                      </td>
                    </tr>
                  </>
                ) : (
                  <></>
                )}
                <tr>
                  <th
                    style={{
                      padding: "8px",
                      borderBottom: "1px solid #B01218",
                      borderTop: "1px solid #B01218",
                      textAlign: "right",
                    }}
                  >
                    GRAND TOTAL :
                  </th>
                  <td
                    style={{
                      padding: "8px",
                      borderBottom: "1px solid #B01218",
                      borderTop: "1px solid #B01218",
                    }}
                  >
                    <b style={{ fontSize: "18px" }}>
                      ₹{" "}
                      {formatNumberNoDacimal(
                        getFlooredFixed(afterExchangeAmountTotal, 0)
                      )}
                    </b>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="text-center">
            <div className="row">
              <div className="col">
                <b>
                  Thank you for your business! We look forward to working with
                  you Again :-)
                </b>
              </div>
              <div className="col">
                <small className="text-muted" style={{ fontSize: "10px" }}>
                  Seetharam Jewellers, Armoor [Dated :{" "}
                  {new Date().toDateString()}]<br></br> Software Credits :
                  Designed and Developed By Ganesh Bondla
                  (https://ganeshbondla.in/) [8074725033]
                </small>
              </div>
            </div>
          </div>
        </div>

        {visiblePrintButton ? (
          <>
            <div
              className="text-center"
              style={{ marginRight: "60px", marginLeft: "60px" }}
            >
              <Link
                to={`/view-order/${orderId}`}
                className="btn btn-info btn-sm float-left"
              >
                Back To Order Details
              </Link>
              <button
                className="btn btn-info btn-sm float-right"
                id="printPageButton"
                onClick={onCLickPrintPage}
              >
                Print
              </button>
              <br></br>
              <br></br>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  });

  return (
    <>
      {accessOnComponent ? (
        dataLoding ? (
          <LoaderShow />
        ) : (
          <>{returnView}</>
        )
      ) : (
        <AccessDenide />
      )}
    </>
  );
};

export default ViewInvoice;
