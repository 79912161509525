import React from "react";

const CheckAccess = () => {
  return (
    <>
      <div class="mt-5 d-flex justify-content-center">
        <div
          class="spinner-border mr-3"
          style={{ fontSize: "16px", height: "25px", width: "25px" }}
          role="status"
        ></div>
      </div>
      <div className="text-center mt-2">
        <b className="baseColor">Checking Access... Please Wait...</b>
      </div>
    </>
  );
};

export default CheckAccess;
