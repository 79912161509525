import React from "react";
import { Link } from "react-router-dom";
import { RoleContext } from "./PrivateRoute/PrivateRoute";

const SideBar = ({ activeNav }) => {
  const { userRole } = React.useContext(RoleContext);
  const workShopRoleBasedAccess = () => {
    if (userRole === "superAdmin") {
      return (
        <>
          <li className="nav-item">
            <Link
              className={
                activeNav === "allWorks" ||
                activeNav === "addNewWork" ||
                activeNav === "addNewWorkShop"
                  ? "nav-link"
                  : "nav-link collapsed"
              }
              data-bs-target="#worksShop-nav"
              data-bs-toggle="collapse"
              to="/list-of-works"
            >
              <i class="bi bi-shop"></i>
              <span>WorkShop</span>
              <i class="bi bi-chevron-down ms-auto"></i>
            </Link>
            <ul
              id="worksShop-nav"
              className={
                activeNav === "allWorks" ||
                activeNav === "addNewWork" ||
                activeNav === "addNewWorkShop"
                  ? "nav-content collapse show"
                  : "nav-content collapse"
              }
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link
                  className={activeNav === "allWorks" ? "active" : ""}
                  to="/list-of-works"
                >
                  <i class="bi bi-dash-circle"></i>
                  <span>List Of Works</span>
                </Link>
              </li>
              <li>
                <Link
                  className={activeNav === "addNewWork" ? "active" : ""}
                  to="/add-new-work"
                >
                  <i class="bi bi-dash-circle"></i>
                  <span>Add New Work</span>
                </Link>
              </li>
              <li>
                <Link
                  className={activeNav === "addNewWorkShop" ? "active" : ""}
                  to="/add-new-workshop"
                >
                  <i class="bi bi-dash-circle"></i>
                  <span>Add New WorkShop</span>
                </Link>
              </li>
            </ul>
          </li>
        </>
      );
    }
    return (
      <>
        <li className="nav-item">
          <Link
            className={
              activeNav === "allWorks" || activeNav === "addNewWork"
                ? "nav-link"
                : "nav-link collapsed"
            }
            data-bs-target="#worksShop-nav"
            data-bs-toggle="collapse"
            to="/list-of-works"
          >
            <i class="bi bi-shop"></i>
            <span>WorkShop</span>
            <i class="bi bi-chevron-down ms-auto"></i>
          </Link>
          <ul
            id="worksShop-nav"
            className={
              activeNav === "allWorks" || activeNav === "addNewWork"
                ? "nav-content collapse show"
                : "nav-content collapse"
            }
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <Link
                className={activeNav === "allWorks" ? "active" : ""}
                to="/list-of-works"
              >
                <i class="bi bi-dash-circle"></i>
                <span>List Of Works</span>
              </Link>
            </li>
            <li>
              <Link
                className={activeNav === "addNewWork" ? "active" : ""}
                to="/add-new-work"
              >
                <i class="bi bi-dash-circle"></i>
                <span>Add New Work</span>
              </Link>
            </li>
          </ul>
        </li>
      </>
    );
  };

  return (
    <>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link
              className={
                activeNav === "dashboard" ? "nav-link" : "nav-link collapsed"
              }
              to="/dashboard"
            >
              <i className="bi bi-grid" />
              <span>Dashboard</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className={
                activeNav === "addOrder" || activeNav === "addOrderRetail"
                  ? "nav-link"
                  : "nav-link collapsed"
              }
              data-bs-target="#expanses-nav"
              data-bs-toggle="collapse"
              to="/add-order"
            >
              <i className="bi bi-cart-plus" />
              <span>Add New Sale</span>
              <i class="bi bi-chevron-down ms-auto"></i>
            </Link>
            <ul
              id="expanses-nav"
              className={
                activeNav === "addOrder" || activeNav === "addOrderRetail"
                  ? "nav-content collapse show"
                  : "nav-content collapse"
              }
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link
                  className={activeNav === "addOrder" ? "active" : ""}
                  to="/add-order"
                >
                  <i class="bi bi-dash-circle"></i>
                  <span>Add Wholesale Order</span>
                </Link>
              </li>
              <li>
                <Link
                  className={activeNav === "addOrderRetail" ? "active" : ""}
                  to="/add-order-retail"
                >
                  <i class="bi bi-dash-circle"></i>
                  <span>Add Retail Order</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <Link
              className={
                activeNav === "allDues" ? "nav-link" : "nav-link collapsed"
              }
              to="/list-dues"
            >
              <i className="bi bi-currency-rupee" />
              <span>List Of Dues</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className={
                activeNav === "searchSaleCustomer"
                  ? "nav-link"
                  : "nav-link collapsed"
              }
              to="/search-sale-customer"
            >
              <i className="bi bi-search" />
              <span>Search: Sale / Customer</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className={
                activeNav === "allStocks" || activeNav === "addStock"
                  ? "nav-link"
                  : "nav-link collapsed"
              }
              data-bs-target="#expanses-nav"
              data-bs-toggle="collapse"
              to="/list-stocks"
            >
              <i class="bi bi-body-text"></i>
              <span>Stocks Manage</span>
              <i class="bi bi-chevron-down ms-auto"></i>
            </Link>
            <ul
              id="expanses-nav"
              className={
                activeNav === "allStocks" || activeNav === "addStock"
                  ? "nav-content collapse show"
                  : "nav-content collapse"
              }
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link
                  className={activeNav === "allStocks" ? "active" : ""}
                  to="/list-stocks"
                >
                  <i class="bi bi-dash-circle"></i>
                  <span>List Of Stocks</span>
                </Link>
              </li>
              <li>
                <Link
                  className={activeNav === "addStock" ? "active" : ""}
                  to="/add-stock"
                >
                  <i class="bi bi-dash-circle"></i>
                  <span>Add New Stock</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <Link
              className={
                activeNav === "allCustomers" ? "nav-link" : "nav-link collapsed"
              }
              to="/list-customers"
            >
              <i class="bi bi-people"></i>
              <span>List Of Customers</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className={
                activeNav === "allExpanses" || activeNav === "addExpanse"
                  ? "nav-link"
                  : "nav-link collapsed"
              }
              data-bs-target="#expanses-nav"
              data-bs-toggle="collapse"
              to="/list-expanses"
            >
              <i class="bi bi-dash-circle"></i>
              <span>Expanses</span>
              <i class="bi bi-chevron-down ms-auto"></i>
            </Link>
            <ul
              id="expanses-nav"
              className={
                activeNav === "allExpanses" || activeNav === "addExpanse"
                  ? "nav-content collapse show"
                  : "nav-content collapse"
              }
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link
                  className={activeNav === "allExpanses" ? "active" : ""}
                  to="/list-expanses"
                >
                  <i class="bi bi-dash-circle"></i>
                  <span>List Of Expanses</span>
                </Link>
              </li>
              <li>
                <Link
                  className={activeNav === "addExpanse" ? "active" : ""}
                  to="/add-expanse"
                >
                  <i class="bi bi-dash-circle"></i>
                  <span>Add New Expanse</span>
                </Link>
              </li>
            </ul>
          </li>

          {workShopRoleBasedAccess()}

          <li className="nav-item">
            <Link
              className={
                activeNav === "invoices" ? "nav-link" : "nav-link collapsed"
              }
              to="/bills-invoices"
            >
              <i class="bi bi-file-earmark-pdf"></i>
              <span>Bills / Invoices</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className={
                activeNav === "payments" ? "nav-link" : "nav-link collapsed"
              }
              to="/payment-transactions"
            >
              <i class="bi bi-credit-card-2-back"></i>
              <span>Payments / Transactions</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className={
                activeNav === "settings" ? "nav-link" : "nav-link collapsed"
              }
              to="/profile-settings"
            >
              <i class="bi bi-gear"></i>
              <span>Profile / Settings</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className={
                activeNav === "upgrade" ? "nav-link" : "nav-link collapsed"
              }
              to="/upgrade-new-version"
            >
              <i class="bi bi-arrow-up-right-circle-fill"></i>
              <span>Upgrade</span>
            </Link>
          </li>

          <hr></hr>

          <li className="nav-item">
            <Link
              className={
                activeNav === "logout" ? "nav-link" : "nav-link collapsed"
              }
              to="/logout"
            >
              <i class="bi bi-box-arrow-right"></i>
              <span>Logout</span>
            </Link>
          </li>

          <hr />
          <b>
            <small>Designed & Developed By</small> <br />{" "}
            <Link to="https://ganeshbondla.in/">Ganesh Bondla</Link>
          </b>
        </ul>
      </aside>
      {/* End Sidebar*/}
    </>
  );
};

export default SideBar;
