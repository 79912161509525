import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ServiceURL } from "../Utils/utils";

const Login = () => {
  const navigate = useNavigate();

  const [loginButtonText, setLoginButtonText] = React.useState("Login");
  const [proceedButton, setProceedButton] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const OnLogin = (e) => {
    setLoginButtonText("Logging in...");
    setProceedButton(true);
    e.preventDefault();
    const loginObject = {
      admin_email: username,
      admin_password: password,
    };

    const endpoint = ServiceURL() + "account/login";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginObject),
    };
    fetch(endpoint, options)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          localStorage.setItem("SRtoken", data.token);
          navigate("/dashboard");
        } else {
          alert(data.message);
        }
        setProceedButton(false);
        setLoginButtonText("Login");
      });
  };

  useEffect(() => {
    localStorage.removeItem("SRtoken");
  }, []);

  return (
    <>
      <main>
        <div className="container">
          <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex justify-content-center py-4">
                    <Link
                      to="/"
                      className="logo d-flex align-items-center w-auto"
                    >
                      <span>SRJ - Login</span>
                    </Link>
                  </div>
                  {/* End Logo */}
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">
                          Login to Your Account
                        </h5>
                        <p className="text-center small">
                          Enter your username &amp; password to login
                        </p>
                      </div>
                      <form className="row g-3" onSubmit={OnLogin}>
                        <div className="col-12">
                          <label htmlFor="yourUsername" className="form-label">
                            Username
                          </label>
                          <div className="input-group has-validation">
                            <span
                              className="input-group-text"
                              id="inputGroupPrepend"
                            >
                              @
                            </span>
                            <input
                              type="text"
                              name="username"
                              className="form-control"
                              id="yourUsername"
                              autoComplete="off"
                              placeholder="Username"
                              required
                              onChange={(e) => setUsername(e.target.value)}
                            />
                            <div className="invalid-feedback">
                              Please enter your username.
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <label htmlFor="yourPassword" className="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            id="yourPassword"
                            autoComplete="off"
                            placeholder="Password"
                            required
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <div className="invalid-feedback">
                            Please enter your password!
                          </div>
                        </div>
                        <div className="col-12">
                          <button
                            className="btn btn-primary w-100"
                            type="submit"
                            disabled={proceedButton}
                          >
                            {loginButtonText}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="credits">
                    {/* Licensing information: https://bootstrapmade.com/license/ */}
                    Designed & Developed by{" "}
                    <Link to="https://ganeshbondla.in/">Ganesh Bondla </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      {/* End #main */}
    </>
  );
};

export default Login;
